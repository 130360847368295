import { FETCH_PRODUCTS } from "../types";
import { network } from "../constants";
import { FILTER_PRODUCTS_BY_SIZE, ORDER_PRODUCTS_BY_PRICE ,ERROR_PRODUCTS,PRODUIT_LOADING,CLEAR_PRODUCTS} from "../types";
export const fetchProducts = (id,offset) => async (dispatch) => {
  dispatch(setProduitLoading());
 
  fetch(`${network.serverip}/TabStock/${id}`).then((res) => res.json())
  .then((data) => {
    console.log(data)
    if(data==''){ dispatch({
      type: ERROR_PRODUCTS,
      payload: "no data"
    });}
    else{
      dispatch({
        type: FETCH_PRODUCTS,
        payload:data,
      });
    }
    
  })

};

export const clearProducts = () => (dispatch) => {
  dispatch({ type: CLEAR_PRODUCTS });
};

export const FilterProd = (donne,offset) => async (dispatch) => {
  console.log(donne)
 
  dispatch(setProduitLoading());

  fetch(`${network.serverip}/filter`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(donne),
    
    
  })  .then((res) => res.json())
  .then((data) => {
    
    if(data==''){ dispatch({
      type: ERROR_PRODUCTS,
      payload: "no data"
    });}
    else{
      dispatch({
        type: FETCH_PRODUCTS,
        payload:data,
      });
    }
    
  })
   

  

};




export const sortProducts = (filteredProducts, sort) => (dispatch) => {
  const sortedProducts = filteredProducts.slice();
  if (sort === "latest") {
    sortedProducts.sort((a, b) => (a._id > b._id ? 1 : -1));
  } else {
    sortedProducts.sort((a, b) =>
      sort === "lowest"
        ? a.price > b.price
          ? 1
          : -1
        : a.price > b.price
        ? -1
        : 1
    );
  }
  console.log(sortedProducts);
  dispatch({
    type: ORDER_PRODUCTS_BY_PRICE,
    payload: {
      sort: sort,
      items: sortedProducts,
    },
  });
};
export const setProduitLoading = () => {
  return {
    type: PRODUIT_LOADING
  };
};