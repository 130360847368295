import { ADD_TO_CART, REMOVE_FROM_CART,CLEAR_CART} from "../types";
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
export const addToCart = (product,Count) => (dispatch, getState) => {
  const cartItems = getState().cart.cartItems.slice();
 
  let alreadyExists = false;

  cartItems.forEach((x) => {
   
      
    if (x.IDArt === product.IDArt ) {
       
       alreadyExists = true;
      
       if(Count>1)
       {
        x.count=Count 
        toast.success("panier mise a jour avec succès",
        {
          position:"top-right",
        
    
        })
       
       }
       else if(product.Qt>0 && x.count+Count>=1)
        {
        x.count = x.count+ Count  ;
       
        toast.success("panier mise a jour avec succès",
        {
          position:"top-right",
        
    
        })
       
      }
    }
  });
  if (!alreadyExists && product.Qt>0 ) {
    cartItems.push({ ...product, count: Count });
    toast.success("produit ajouté au panier",
    {
      position:"top-right",
      
     

    })
  }
  
  
  dispatch({
    type: ADD_TO_CART,
    payload: { cartItems },
  });
  localStorage.setItem("cartItems", JSON.stringify(cartItems));
};

export const removeFromCart = (product) => (dispatch, getState) => {

  const cartItems = getState()
    .cart.cartItems.slice()
    .filter((x) => x.IDArt != product.IDArt);
    Swal.fire({
      title: 'Retirer du Panier',
      text: "Voulez-vous vraiment supprimer cet article du panier!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Retirer'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({ type: REMOVE_FROM_CART, payload: { cartItems } });
        Swal.fire(
          '',
          'Le produit a été retiré du panier',
          'success'
        )
      }
    });
   
 
  localStorage.setItem("cartItems", JSON.stringify(cartItems));
};
export const clearCart = () => (dispatch) => {

  dispatch({ type: CLEAR_CART });
  localStorage.setItem("cartItems", []);
};
