import {
  FETCH_PRODUCTS,
  FILTER_PRODUCTS_BY_SIZE,
  ORDER_PRODUCTS_BY_PRICE,ERROR_PRODUCTS,PRODUIT_LOADING
  ,CLEAR_PRODUCTS
} from "../types";

export const productsReducer = (state = {products:[]}, action) => {
  
  switch (action.type) {
    case FILTER_PRODUCTS_BY_SIZE:
      return {
        ...state,
        size: action.payload.size,
        filteredItems: action.payload.items,
      };
      case ERROR_PRODUCTS:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case ORDER_PRODUCTS_BY_PRICE:
      return {
        ...state,
        sort: action.payload.sort,
        filteredItems: action.payload.items,
        loading: false
      };
      case PRODUIT_LOADING:
        return {
          ...state,
          loading: true
        };
    case FETCH_PRODUCTS:
      return {products:action.payload, items: action.payload, filteredItems: action.payload };
      case CLEAR_PRODUCTS:
      return {filteredItems: []};
    default:
      return state;
  };

  
};
