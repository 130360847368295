import { FETCH_IMG,FETCH_IMGART} from "../types";
import { network } from "../constants";
import React from 'react'
export const fetchImg = ( ) => async (dispatch) => {
  const res = await  fetch(`${network.serverip}/Img`);
  const data = await res.json();
  dispatch({
    type: FETCH_IMG,
    payload: data,
  });
};

export const fetchImageProd = (product) => async (dispatch) => {
  console.log(product)
 const image=[];
 product.forEach(el => {
   fetch(`api/ImgProd/${el.IDArt}`)
  .then(res => res.json()).then(dat => { image.push({ IDArt: dat[0].IDArt, imgArt: dat[0].imgArt }) })
 })
 console.log(image)
  dispatch({
    type: FETCH_IMGART,
    payload: image,
  });
};