import React, { useEffect, useState } from 'react';
import './marq.css' 
import image8 from '../assets/glaces.jpg'
import image9 from '../assets/Image13.png'
import {
  MDBCol,
  MDBRow,
  MDBCard,
  MDBAnimation,
  MDBMask, MDBView,
  MDBBtn,
  MDBContainer

} from 'mdbreact';
import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';
import Carousel from "react-multi-carousel";
import { Image } from "semantic-ui-react";
import "react-multi-carousel/lib/styles.css";
import './HomePage.css';
import Img from '../actions/Img';
import CarouselPage from './carousal';
import { Button, Dropdown, ButtonGroup, Modal, Form, Row, Col, Card } from '@themesberg/react-bootstrap';
import { fetchImg } from '../actions/imageAction'
import spinner from './spinner.gif';
import Marquee from "react-fast-marquee";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { clearProducts } from "../actions/productActions";
import { network } from "../constants";
const name = localStorage.getItem("jwtname")
export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    // paritialVisibilityGutter: 60,
    slidesToSlide: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    // paritialVisibilityGutter: 50,
    slidesToSlide: 4

  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
    paritialVisibilityGutter: 30,
    slidesToSlide: 3
  }
};
function Marq(props) {
  let history = useHistory();
  const [fam, setfam] = useState('%');
  const [marque, setmarque] = useState('');
  const [modele, setmodele] = useState('%');
  const [Allmarque, setAllmarque] = useState([]);
  const [AllFrs, setAllFrs] = useState([]);
  
  useEffect(() => {
    if (marque != '') {
      props.clearProducts()
      history.push({
        pathname: '/ProdFilter',
        search: `?${fam, modele, marque}`,
        state: { fam, modele, marque }
      })
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }



  }, [marque])

  React.useEffect(() => {
    //  if (!props.images) { props.fetchImg(); }
    fetch(`${network.serverip}/Img`).then((response) => response.json())
      .then((result) => { setAllmarque(result.filter(result => result.id ==1)); setAllFrs(result.filter(result => result.id ==0)) })
    const widh = window.innerWidth
    if (widh > 728) { setWI(150) }

  }, []);
  const [WI, setWI] = React.useState(70);


  const tabmarque = ["HYUNDAI", "ISUZU", "NISSAN", "MAZDA", "TOYOTA", "MITSUBISHI", "FORD", "HONDA"]





  return (


    <div >
      {/* <div className='d-flex flex-row justify-content-center text-center' >
          <h3 className=' h1-responsive text-center  text-warning  display-5 font-weight-bold ' > Bienvenue   </h3>
          <h3 className=' h1-responsive bold text-center   mx-1 display-5 font-weight-bold' > {name} </h3>
        </div> */}





      <div className='my-5 mb-5'  >
        {/* <MDBContainer>
          <MDBCarousel showIndicators showControls fade>
            <MDBCarouselItem
              className='w-90 d-block'
              itemId={1}
              src='https://i.ibb.co/2WvyKHT/336771771-617170553248950-3466342433492792495-n.jpg'
              alt='...'
            >
            
            </MDBCarouselItem>




          </MDBCarousel>
        </MDBContainer>
       */}



        {!Allmarque ? (
          <MDBRow center  >
            <MDBCol sm={4}></MDBCol>
            <MDBCol >
              <div>
                <img
                  src={spinner}
                  style={{ marginTop: '50px', width: '200px', display: 'block', float: 'center' }}
                  alt="Loading..."
                />
              </div>
            </MDBCol>
            <MDBCol sm={4}></MDBCol>
          </MDBRow>
        ) : (



          <div className='container mt-5 '>
            <h3>Marques</h3>
            <div className='d-flex center' style={{ justifyItems: 'center', alignItems: 'center' }} >
              <Row className='d-flex'  >

                {Allmarque.map(image => {

                  return (

                    <Col around className='my-2 justify-content-center d-flex' onClick={() => { setmarque(image.Marque) }}>




                      {/* <Img product={image.ImgMarque} style={{width:200,height:100, justifyItems:'center', }} /> */}
                      <img
                        //className='p-2 border border-dark'
                        src={image.Urlimg}
                        alt=""
                        style={{ width: 200, height: 100, justifyItems: 'center', 
                        
                        border: "0.5px solid black" 
                       }}

                      />



                    </Col>

                  )
                })}

              </Row>
            </div>
          </div>
        )}
        {/* <MDBRow id='categories' center style={{ maxWidth: WI*7}}>


                <Marquee speed={30} >


                  {props.images.map((img) => (


                    <div>

                      {tabmarque.includes(img.Marque) && (

                        <MDBCol size='3' md='4' key={img.Marque}>

                          <MDBAnimation reveal type='fadeInLeft'>
                            <MDBCard cascade className='my-3  lighten-4' style={{ width: WI, height: WI }} >



                              <MDBView hover zoom>
                                <Img product={img.ImgMarque} style={{ width: WI, height: WI }} />
                                <MDBMask className="flex-center" overlay="blue-light">

                                </MDBMask>
                              </MDBView>


                            </MDBCard>
                          </MDBAnimation>



                        </MDBCol>
                      )}

                    </div>

                  ))}
                </Marquee>

              </MDBRow> */}

        <div className='container my-3 justify-container-center'>
          <h3>Categories</h3>
          <Row className='row-cols-md-1 row-cols-md-2 row-cols-md-3'>
            <Col className='my-2'>
              <Card className='border-1  d-flex flex-row h-100'>
                <div>
                  <img className='mt-1' src="https://i.ibb.co/QKVHNhb/frein.jpg" style={{ width: 100, height: 80 }} />
                </div>
                <div className='cat py-2'>
                  <h5 className='mx-4 mb-5'>Freins</h5>
                  <ul >
                    <li><h6>Plaquettes de frein</h6></li>
                    <li><h6>Disque de frein</h6></li>
                    <li><h6>Kit de réparation d'etrier de frein</h6></li>
                    <li><h6>Freins à tambour</h6></li>
                    <li><h6>Etrier de frein</h6></li>
                  </ul>
                </div>
              </Card>
            </Col>
            <Col className='my-2'>
              <Card className='border-1  d-flex flex-row h-100'>
                <div>
                  <img className='mt-1' src="https://i.ibb.co/cNKDjvX/Filtre.jpg" style={{ width: 100, height: 70 }} />
                </div>
                <div className='cat py-2'>
                  <h5 className='mx-4 mb-5'>Filtre</h5>
                  <ul >
                    <li><h6>Filtre à huile</h6></li>
                    <li><h6>Filtre à air</h6></li>
                    <li><h6>Filtre à d'habitacle</h6></li>
                    <li><h6>Filtre à carburant</h6></li>

                  </ul>
                </div>
              </Card>

            </Col>

            <Col className='my-2'>
              <Card className='border-1  d-flex flex-row h-100'>
                <div>
                  <img className='mt-1  mx-2' src="https://i.ibb.co/KVqJ2pf/suspension.jpg" style={{ width: 100, height: 80 }} />
                </div>
                <div className='cat py-2'>
                  <h5 className='mx-4 mb-5' >Suspension</h5>
                  <ul >
                    <li><h6>Moyeux de roue</h6></li>
                    <li><h6>Roulement de roue</h6></li>
                    <li><h6>Biellette de barre stabilisatrice</h6></li>
                    <li><h6>Bras de suspension</h6></li>
                    <li><h6>Rotule de direction</h6></li>
                    <li><h6>Rotule de suspension</h6></li>
                  </ul>
                </div>
              </Card>

            </Col>


            <Col className='my-2'>
              <Card className='border-1  d-flex flex-row h-100'>
                <div>
                  <img className='mt-1 mx-2' src="https://i.ibb.co/C8bnyhN/Image4.png" style={{ width: 100, height: 80 }} />
                </div>
                <div className='cat py-2'>
                  <h5 className='mx-4 mb-5'>Carrosserie</h5>
                  <ul >
                    <li><h6>Verin de hayon</h6></li>
                    <li><h6>Rétroviseur extérieur</h6> </li>
                    <li><h6>Glace de rétroviseur</h6></li>

                  </ul>
                </div>
              </Card>
            </Col>
            <Col className='my-2'>
              <Card className='border-1  d-flex flex-row h-100'>
                <div>
                  <img className='mt-1  mx-2' src="https://i.ibb.co/PcnNb1c/Mask-Group2.png" style={{ width: 100, height: 70 }} />
                </div>
                <div className='cat py-2'>
                  <h5 className='mx-4 mb-5'>Moteur</h5>
                  <ul >
                    <li><h6>Batterie de voiture</h6></li>
                    <li><h6>Allumage et préchauffage</h6></li>
                    <li><h6>Bouchon de vidange</h6></li>
                    <li><h6>Embrayage</h6></li>
                    <li><h6>Support moteur</h6></li>
                  </ul>
                </div>
              </Card>

            </Col>
            <Col className='my-2'>
              <Card className='border-1  d-flex flex-row h-100'>
                <div>
                  <img className='mt-1 mx-2' src="https://i.ibb.co/1JGTtPn/Image6.png" style={{ width: 100, height: 80 }} />
                </div>
                <div className='cat py-2'>
                  <h5 className='mx-4 mb-5'>Commande à courroie / chaîne</h5>
                  <ul >
                    <li><h6>Kit de distribution</h6></li>
                    <li><h6>Courroie Poly-V</h6></li>
                    <li><h6>Pompe à eau</h6></li>
                    <li><h6>Kie de courroie de distribution</h6></li>

                  </ul>
                </div>
              </Card>

            </Col>


            <Col className='my-2'>
              <Card className='border-1  d-flex flex-row h-100'>
                <div>
                  <img className='mt-1' src="https://i.ibb.co/NsGNFxJ/Mask-Group.png" style={{ width: 100, height: 80 }} />
                </div>
                <div className='cat py-2'>
                  <h5 className='mx-4 mb-5'>Amortissement</h5>
                  <ul >
                    <li><h6>Amortisseur</h6></li>
                    <li><h6>Coupelle d'amortisseur</h6></li>
                    <li><h6>Ressort de suspension</h6></li>
                    <li><h6>Soufflet d'amortisseur et boutée</h6></li>
                    <li><h6>élastique suspension </h6></li>
                  </ul>
                </div>
              </Card>
            </Col>
            <Col className='my-2'>
              <Card className='border-1  d-flex flex-row h-100'>
                <div>
                  <img className='mt-1' src="https://i.ibb.co/ChpBH3m/glaces.jpg" style={{ width: 100, height: 70 }} />
                </div>
                <div className='cat py-2'>
                  <h5 className='mx-4 mb-5'>Système d'essuie-glaces</h5>
                  <ul >
                    <li><h6>Essuie-glaces</h6></li>
                    <li><h6>Bras Essuie-glaces</h6></li>
                    <li><h6>Pompe de lave-glace</h6></li>


                  </ul>
                </div>
              </Card>

            </Col>
            <Col className='my-2'>
              <Card className='border-1  d-flex flex-row h-100'>
                <div>
                  <img className='mt-1' src="https://i.ibb.co/TrbW0B6/Image13.png" style={{ width: 100, height: 80 }} />
                </div>
                <div className='cat py-2'>
                  <h5 className='mx-4 mb-5'>Refroidissement moteur</h5>
                  <ul >
                    <li><h6>calorstat</h6></li>
                    <li><h6>Pompe à eau</h6></li>
                    <li><h6>Radiateur de refroidissement moteur</h6></li>
                    <li><h6>Sondre de température</h6></li>
                    <li><h6>Liquide de refroidissement</h6></li>

                  </ul>
                </div>
              </Card>

            </Col>
          </Row>

        </div>
        <div className='my-5 bg-light just'>
          <div className='container py-5' >
            <h3 >Top constructeurs</h3>

              <Carousel
              className='mt-5'
              autoPlay={true}
              autoPlaySpeed={2500}
              transitionDuration={5000}
              containerClass="carousel-container"
              infinite={true}
              responsive={responsive}
              // showDots={true}
              draggable={false}
              customTransition="all .5"
            >

              {AllFrs.map(image => {


                return (
                  // !tabmarque.includes(image.Marque) && (

<img
                        //className='p-2 border border-dark'
                        src={image.Urlimg}
                        alt=""
                        style={{ width: 270, height: 150, justifyItems: 'center', }}

                      />
                  // <Img className='mx-2' product={image.ImgMarque} style={{ width: '75%', height: 70 }} />



                )

              })}

            </Carousel>  
          </div>
        </div>
        {/* <Marquee speed={30} >


                  {props.images.map((img) => (

                    <div>

                      {!tabmarque.includes(img.Marque) && (
                        <MDBCol size='3' md='4' key={img.Marque}>

                          <MDBCard cascade className='my-3  lighten-4' style={{ width: WI, height: WI }} >



                            <MDBView hover zoom>
                              <Img product={img.ImgMarque} style={{ width: WI, height: WI }} />
                              <MDBMask className="flex-center" overlay="blue-light">

                              </MDBMask>
                            </MDBView>


                          </MDBCard>



                        </MDBCol>
                      )}

                    </div>
                  ))}
                </Marquee  > */}



      </div>
    </div>
  )
}

export default connect(
  (state) => ({ images: state.image.images }),
  {
    fetchImg,
    clearProducts
  }
)(Marq);
