import React from 'react'
import {
  MDBCard,
  MDBCardTitle,
  MDBContainer,
  MDBCardBody,
  MDBCardHeader,
  MDBNotification,
  MDBIcon, MDBModalHeader,
  MDBCol,
  MDBRow,
  MDBBadge,
  MDBBtn,
  MDBAlert,
  MDBTable,
  MDBTableBody,
  MDBAnimation, MDBModal, MDBModalBody, MDBCardFooter,
  MDBTableHead,
  MDBModalFooter
} from 'mdbreact';
import { network } from "../constants";

import { addToCart, removeFromCart, clearCart } from "../actions/cartActions";
import { createOrder, clearOrder } from "../actions/orderActions";
import { connect } from "react-redux";

import { useHistory } from 'react-router-dom';

import { Typeahead } from 'react-bootstrap-typeahead';

import withReactContent from 'sweetalert2-react-content'

import Swal from 'sweetalert2'
export const Cart = (props) => {


  const niveau = localStorage.getItem("niveau")
  const remise = localStorage.getItem("remise")
  const [Not, setNot] = React.useState(false);
  const [error, seterror] = React.useState(false);
  const [update, setupdate] = React.useState(false);
  const [modal, setmodal] = React.useState(false);
  const [modal2, setmodal2] = React.useState(false);
  const [data2, setdata2] = React.useState([])
  const [selected, setSelected] = React.useState([]);

  React.useEffect(() => {

    if (niveau == 2) {
      fetch(`${network.serverip}/ListSoc`).then(res => res.json()).then(dat => setdata2(dat))
    }

  }, [update])


  const handleClick = (id, count) => {
    setupdate(true);
    if (count >= 1) {

      var product = cartItems.filter((item) => item.IDArt === id)
      props.addToCart(product[0], count);
    } else {
      var product = cartItems.filter((item) => item.IDArt === id)

      props.addToCart(product[0], product[0].Qt)
      window.location.reload();
    }


  }

  const { cartItems } = props;
  const decoded = localStorage.getItem("jwtToken")
  const code = localStorage.getItem("code")
  const client = localStorage.getItem("client")
  const history = useHistory();


  const create = (e) => {
    if (niveau == 2 && selected[0] || niveau == 0) {
      const order = {

        list: cartItems,
        TotHT: (cartItems.reduce((a, c) => a + c.PrixVente * c.count, 0.000)).toFixed(3),
        TotRem: (cartItems.reduce((a, c) => a + c.PrixVente * c.count * (remise / 100), 0.000)).toFixed(3),
        TotTTC: (cartItems.reduce((a, c) => a + c.PrixVente * c.count * (1 - remise / 100) * (1 + c.Tva / 100), 0.000)).toFixed(3),
        TotTva: (cartItems.reduce((a, c) => a + c.PrixVente * c.count * (1 - remise / 100) * (c.Tva / 100), 0)).toFixed(3),
        idClient: niveau == "2" ? selected[0].idtiers : decoded,
        remise: remise ? remise : 0,
        dateCom: new Date(),
        langht: cartItems.length,
        codrep: niveau == "2" && code


      };
      const MySwal = withReactContent(Swal)

      props.createOrder(order);
      props.clearCart();
      window.scrollTo(0, 0);
      localStorage.removeItem("cartItmes")
      cartItems.length = 0;
      setmodal2(false);

      MySwal.fire({
        position: 'top',
        icon: 'success',
        title: 'Creation avec succes   !',
        showConfirmButton: false,
        timer: 1500
      }).then((res) => {
        if (res) {
          history.push('/')
        }
      })




    } else { seterror(true) }
  }
  return (
    <div>
     
      {Not && (<MDBAnimation reveal type='fadeInDown'>

        < MDBAlert color="success" style={{ marginTop: 200 }} >
          <MDBIcon icon="check" className='success-text' /> Votre commande est bien reçue
          {window.innerWidth > 720 && (<MDBBtn color="success " size="sm" onClick={() => setNot(false)}>ok</MDBBtn>)}


        </MDBAlert></MDBAnimation>)}

      {cartItems.length > 0 ?
        (
          <div className='mt-4'>
            <h3 className='mx-5 my-3'>Détails Commande :</h3>


            {niveau == 2 &&
              <div>
                <MDBRow className='mx-3' >

                  <MDBCol sm={6}>
                    <Typeahead
                      id="basic-example"

                      onChange={(select) => {
                        setSelected(select); console.log(select); seterror(false); if (select[0]) {
                          localStorage.setItem('remise', select[0].remise)
                          localStorage.setItem('client', JSON.stringify(select))
                        }
                      }}
                      options={data2}
                      placeholder="Séléctionner Client ...."
                      selected={selected}
                      inputProps={{ required: true }}
                    />
                    {selected.length > 0 &&
                      <div className='mx-3 my-3'>
                        <p className='my-1'><strong>Matricule :</strong> {selected[0].codtva}</p>
                        <p className='my-1'><strong>Ville :</strong> {selected[0].ville}</p>
                        <p className='my-1'><strong>Remise :</strong> {selected[0].remise}</p>
                      </div>
                    }
                  </MDBCol>
                </MDBRow>


                {error &&
                  <p className='red-text' > Slectionnez le nom de client svp !!  </p>
                }
              </div>
            }
            <MDBCard style={{ marginTop: 10 }} >

              <MDBCardBody>

                <MDBTable >
                  <MDBTableHead>
                    <tr>

                      <th>Ref.</th>
                      <th>image</th>
                      <th colSpan={3}>Libellé</th>

                      <th colSpan={2}> Quantité</th>
                      <th>P.U.HT</th>
                      <th>Rem.</th>
                      <th>Net.HT</th>
                      <th>TVA</th>
                      <th colSpan={2}>Net.TTC</th>
                      <th />
                    </tr>
                  </MDBTableHead>

                  <MDBTableBody>
                    {cartItems.map((item) => (
                      <tr key={item.IDArt}>

                        <td>{item.Codart}</td>
                        <td><img src={item.ExLibArt} style={{ width: '100px', height: '100px' }} /></td>
                        <td colSpan={3}>{item.LibArt}</td>

                        <td colSpan={2}>
                          <div className='d-flex'>
                            {/* <MDBIcon  className='black-text py-1 px-1 mx-1' icon='minus' size='x'
                   style={{padding:"15px" ,background :"#e7e7e7",borderRadius: '30px', color: 'red' }}
                   onMouseOver={(event) => { event.target.style.background = '#fdd835' }}
                   onMouseOut={(event) => event.target.style.background = '#e7e7e7'}
                   onClick={ ()=> handleClick(item.IDArt, -1)}
                   /> */}

                            <input
                              name={item.Codart}
                              type="number"
                              className="d-flex mt-2"
                              id={item.IDArt}
                              style={{ width: '65px', height: '98%', border: 'none' }}
                              Value={item.count}
                              min={1}
                              max={item.Qt}
                              //onKeyPress={(e) => {e.preventDefault();}}
                              // onMouseEnter={(e) => setid(e.target.name)}
                              // onKeyDown={(e) => { e.key === 'Enter'? handleClick(item.IDArt, Number(e.target.value)) : (null) }}
                              onChange={(e) => { handleClick(item.IDArt, Number(e.target.value)) }}
                            // onLoad={e => e.target.value}
                            />
                            {/* <strong className='red-text center'>{item.count}</strong> */}
                            {/* <MDBIcon  className='black-text py-1 px-1 ' icon='plus' size='x'
                   style={{padding:"15px" ,background :"#e7e7e7",borderRadius: '30px', color: 'red' }}
                   onMouseOver={(event) => { event.target.style.background = '#fdd835' }}
                   onMouseOut={(event) => event.target.style.background = '#e7e7e7'}
                   onClick={ ()=> handleClick(item.IDArt, 1)}
                   /> */}
                            <MDBIcon className='mx-3 my-2'
                              onClick={() => props.removeFromCart(item)}
                              icon="trash-alt" size="x" style={{ borderRadius: '30px', color: 'red' }} />
                          </div>
                        </td>
                        <td>{item.PrixVente.toFixed(3)}</td>
                        <td>{remise ? remise : ''}</td>
                        <td>{(item.PrixVente * (1 - remise / 100) * item.count).toFixed(3)}</td>
                        <td>{item.Tva}</td>
                        <td colSpan={2}>{(item.PrixVente.toFixed(3) * (1 - remise / 100) * (1 + item.Tva / 100)).toFixed(3)}</td>

                      </tr>
                    ))}
                    <tr><td /></tr>
                    <tr style={{ backgroundColor: "#e7e7e7" }}>

                      <td align='right'>
                        <strong>Brut.HT </strong>
                      </td><td align='left' style={{ color: '#0000CD' }}> {(cartItems.reduce((a, c) => a + c.PrixVente * c.count, 0.000)).toFixed(3)}</td>
                      <td>
                        <strong>Remise </strong>
                      </td><td style={{ color: '#0000CD' }}>{(cartItems.reduce((a, c) => a + c.PrixVente * (remise / 100) * c.count, 0.000)).toFixed(3)} </td>
                      <td>
                        <strong>Tot.HT </strong>
                      </td>
                      <td  >
                        <strong style={{ color: '#006400' }}> {(cartItems.reduce((a, c) => a + c.PrixVente * c.count * (1 - remise / 100), 0.000)).toFixed(3)} </strong>
                      </td>
                      <td />
                    </tr>
                    <tr style={{ backgroundColor: "#e7e7e7" }}>

                      <td align='right'>
                        <strong>Tot.Tva</strong>
                      </td>
                      <td style={{ color: '#0000CD' }}>
                        {(cartItems.reduce((a, c) => a + c.PrixVente * c.count * (1 - remise / 100) * (c.Tva / 100), 0)).toFixed(3)}
                      </td>
                      <td>
                        <strong >Tot.Fodec</strong>
                      </td>
                      <td style={{ color: '#0000CD' }}>0.000</td>
                      <td>
                        <strong>Net.TTC </strong>
                      </td>
                      <td>
                        <strong style={{ color: 'red' }}> {(cartItems.reduce((a, c) => a + c.PrixVente * c.count * (1 - remise / 100) * (1 + c.Tva / 100), 0.000)).toFixed(3)} </strong>
                      </td>
                      <td />
                    </tr>
                  </MDBTableBody>
                </MDBTable>
              </MDBCardBody>
              <MDBCardFooter>
                <MDBRow center >
                  <MDBCol sm={10}></MDBCol>
                  <MDBCol>
                    <MDBBtn className='white-text' color="red" onClick={(e) => create(e)} size='md'
                    >
                      Envoyer Commande
                    </MDBBtn>
                  </MDBCol>


                </MDBRow>
              </MDBCardFooter>
            </MDBCard>

          </div>
        ) :
        <MDBCol  >
        </MDBCol>
      }


      {/* <Footer/>  */}
    </div>
  )
}

export default connect(
  (state) => ({
    order: state.order.order,
    cartItems: state.cart.cartItems,
    user: state.pass.user

  }),
  { addToCart, removeFromCart, createOrder, clearOrder, clearCart }
)(Cart);

