import { ERROR_LOGIN,FETCH_USER,SET_CURRENT_USER} from "../types";
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import { network } from "../constants";
import {toast} from 'react-toastify';
import axios from 'axios';
export const fetchUser = (nom , password ,history) => async (dispatch) => {
 
  let user ={
    nom ,password
   }
   if (!nom || !password) {
    toast.error("Veuillez remplir tous les champs.") // Set error message for empty fields
    return;
  }
  const res = await axios.post(`${network.serverip}/Login`, user);

  
  const { msg , success,Token} = res.data;
 
 if(success ===false){
  toast.error(msg)
}else
{
   const decoded = jwt_decode(Token);

 
  // localStorage.setItem('jwtToken',decoded.id);
  localStorage.setItem('code',decoded.user.recordset[0]['CodTiers']);
  localStorage.setItem('remise',decoded.user.recordset[0]['Remise']);
  localStorage.setItem('jwtname',decoded.user.recordset[0]['Raisoc']);
  localStorage.setItem('niveau',decoded.user.recordset[0]['niveau']);
  localStorage.setItem('jwtToken',decoded.user.recordset[0]['IDTiers']);
  dispatch(setCurrentUser(decoded.user.recordset[0]))
  history.push('/');
  
}


  
 

};

export const setCurrentUser = token => {

  

  return {
    type: SET_CURRENT_USER,
    payload: {token},
    
  };

};
export const getError = data => {

  return {
    type: ERROR_LOGIN,
    payload: data,
    
  };
};

// Log user out
export const logoutUser = () => dispatch => {
  localStorage.clear();
 
  window.location.href = '/login';
  
  dispatch(setCurrentUser({}));
};