import './Footer.css'
import React,{useState, useEffect} from 'react';
import { Button, Dropdown, ButtonGroup, Modal, Form, Row, Col, Card } from '@themesberg/react-bootstrap';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { clearProducts } from "../src/actions/productActions";
const Footer = (props) => {
  let history = useHistory();
  const [fam, setfam] = useState('%');
  const [marque, setmarque] = useState('');
  const [modele, setmodele] = useState('%');

  useEffect(() => {
    if(marque!='')
    {
      props.clearProducts()
      history.push({
        pathname: '/ProdFilter',
        search: `?${fam, modele, marque}`,
        state: { fam, modele, marque }
    })
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
   


  }, [marque])
  return (
    <div className='my-5'>
       <Row>
       <Col></Col>
        <Col>
      <div class="img has-hover x md-x lg-x y md-y lg-y" id="image_979661112">
              <div class="img-inner dark">
    <img width="207" height="67" src="https://amenedistribution.com/wp-content/uploads/2023/09/logo-1.png" class="attachment-original size-original" alt="" decoding="async" loading="lazy"/>						
        </div>

        <div className='d-flex'>
        <div class="icon-box-img mt-3 " style={{width: "20px",marginRight:"30px"}}>
      <div class="icon">
        <div class="icon-inner">
          <img width="48" height="48" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjVpkSceEqCzVkMsMK-V_TdZFRZOmzwS3gyrZGnrpT7Q&s" class="attachment-medium size-medium" alt="" decoding="async" loading="lazy"/>					</div>
      </div>
    </div> 
        
    <div class="icon-box-img mt-3" style={{width: "20px",marginRight:"30px"}}>
      <div class="icon">
        <div class="icon-inner">
          <img width="50" height="50" src="https://i.ibb.co/FDdZdZ7/t-l-chargement.png"  alt="" decoding="async" loading="lazy"/>					</div>
      </div>
    </div> 
    </div>
</div>
</Col>
<Col>
<div id="text-1934553008" class="text">
  

<h4 style={{color:"rgb(194, 39, 47)"}}>Contactez nous</h4>
<div class="d-flex mt-5">
        <div class="icon-box-img" style={{width: "20px",marginRight:"30px"}}>
      <div class="icon">
        <div class="icon-inner">
          <img width="32" height="32" src="https://amenedistribution.com/wp-content/uploads/2023/12/cartes-et-drapeaux-1.png" class="attachment-medium size-medium" alt="" decoding="async" loading="lazy"/>					</div>
      </div>
    </div>
      <div class="icon-box-text last-reset mb-3">
                

<div id="text-1515607157" class="text">
  

<p>Saida Sidi Bouzid 9115 TUNIS</p>
  

</div>
</div>
</div>

</div>
<div class="d-flex">
        <div class="icon-box-img" style={{width: "20px",marginRight:"30px"}}>
      <div class="icon">
        <div class="icon-inner">
          <img width="32" height="32" src="https://amenedistribution.com/wp-content/uploads/2023/12/telephone-intelligent-1.png" class="attachment-medium size-medium" alt="" decoding="async" loading="lazy"/>					</div>
      </div>
    </div>
      <div class="icon-box-text last-reset mt-3"></div>
<div id="text-3227810372" class="text">
  

<p><b>+216 29 310 215 / 29 310 729</b></p>
  


</div>
</div>
<div class="d-flex  mt-3">
        <div class="icon-box-img" style={{width: "20px",marginRight:"30px"}}>
      <div class="icon">
        <div class="icon-inner">
          <img width="32" height="32" src="https://amenedistribution.com/wp-content/uploads/2023/12/email-2.png" class="attachment-medium size-medium" alt="" decoding="async" loading="lazy"/>					</div>
      </div>
    </div>
      <div class="icon-box-text last-reset">
                

<div id="text-3987428030" class="text">
  

<p>contact@amenedistribution.com</p>
  

</div>
</div>
</div>
</Col>
<Col>

<h4 style={{color:"rgb(194, 39, 47)"}}>Liens rapides</h4>
        <ul className='mt-5' >
        <li><i className='me-2 mt-3 fa fa-angle-right orange-text'></i>A propos</li>
        <li><i className='me-2 mt-3 fa fa-angle-right orange-text'></i>Contact</li>
        <li><i className='me-2 mt-3 fa fa-angle-right orange-text'></i>Nos services</li>
        <li><i className='me-2 mt-3 fa fa-angle-right orange-text'></i>Livraison</li>
        </ul>
        
</Col>
</Row>
{/* <div class="social-icons follow-icons full-width text-center"><a href="https://www.facebook.com/profile.php?id=100084767987578" target="_blank" rel="noopener noreferrer nofollow" data-label="Facebook" class="icon primary button circle facebook tooltip" title="Nous suivre sur Facebook" aria-label="Nous suivre sur Facebook"><i class="icon-facebook"></i></a><a href="mailto:contact@amenedistribution.com" data-label="E-mail" target="_blank" rel="nofollow" class="icon primary button circle email tooltip" title="Nous envoyer un email" aria-label="Nous envoyer un email"><i class="icon-envelop"></i></a></div> */}


  
      {/* <div class="row" id="row-246334623">


<div id="col-69047903" class="col medium-3 small-12 large-3">
      <div class="col-inner">
    
    

<div class="img has-hover x md-x lg-x y md-y lg-y" id="image_979661112">
              <div class="img-inner dark">
    <img width="207" height="67" src="https://amenedistribution.com/wp-content/uploads/2023/09/logo-1.png" class="attachment-original size-original" alt="" decoding="async" loading="lazy"/>						
        </div>
              

</div>


<div class="social-icons follow-icons full-width text-center"><a href="https://www.facebook.com/profile.php?id=100084767987578" target="_blank" rel="noopener noreferrer nofollow" data-label="Facebook" class="icon primary button circle facebook tooltip" title="Nous suivre sur Facebook" aria-label="Nous suivre sur Facebook"><i class="icon-facebook"></i></a><a href="mailto:contact@amenedistribution.com" data-label="E-mail" target="_blank" rel="nofollow" class="icon primary button circle email tooltip" title="Nous envoyer un email" aria-label="Nous envoyer un email"><i class="icon-envelop"></i></a></div>


  </div>
        </div>



<div id="col-1465423493" class="col medium-6 small-12 large-6">
      <div class="col-inner">
    
    

<div id="text-1934553008" class="text">
  

<h4 style="color:rgb(194, 39, 47)">Contactez nous</h4>
  

</div>

<div id="gap-479677122" class="gap-element clearfix" style="display:block; height:auto;">
  

</div>



  <div class="icon-box featured-box icon-box-left text-left">
        <div class="icon-box-img" style="width: 20px">
      <div class="icon">
        <div class="icon-inner">
          <img width="32" height="32" src="https://amenedistribution.com/wp-content/uploads/2023/12/cartes-et-drapeaux-1.png" class="attachment-medium size-medium" alt="" decoding="async" loading="lazy"/>					</div>
      </div>
    </div>
      <div class="icon-box-text last-reset">
                

<div id="text-1515607157" class="text">
  

<p>Saida Sidi Bouzid 9115 TUNIS</p>
  

</div>


  </div>
</div>



  <div class="icon-box featured-box icon-box-left text-left">
        <div class="icon-box-img" style="width: 20px">
      <div class="icon">
        <div class="icon-inner">
          <img width="32" height="32" src="https://amenedistribution.com/wp-content/uploads/2023/12/email-2.png" class="attachment-medium size-medium" alt="" decoding="async" loading="lazy"/>					</div>
      </div>
    </div>
      <div class="icon-box-text last-reset">
                

<div id="text-3987428030" class="text">
  

<p>contact@amenedistribution.com</p>
  

</div>


  </div>
</div>



  <div class="icon-box featured-box icon-box-left text-left">
        <div class="icon-box-img" style="width: 20px">
      <div class="icon">
        <div class="icon-inner">
          <img width="32" height="32" src="https://amenedistribution.com/wp-content/uploads/2023/12/telephone-intelligent-1.png" class="attachment-medium size-medium" alt="" decoding="async" loading="lazy"/>					</div>
      </div>
    </div>
      <div class="icon-box-text last-reset">
                

<div id="text-3227810372" class="text">
  

<p><b>+216 29 310 215 / 29 310 729</b></p>
  


</div>


  </div>
</div>


<div id="gap-371889486" class="gap-element clearfix" style="display:block; height:auto;">
  

</div>



  </div>
        </div>



<div id="col-1810259985" class="col medium-3 small-12 large-3">
      <div class="col-inner">
    
    

<div id="text-718810912" class="text">
  

<h4>Liens rapides</h4>
<ul style="rgb(235, 93, 39)">
<li style="color:rgb(235, 93, 39)" class="bullet-arrow"><span style="font-size: 90%; color:rgb(235, 93, 39)"><a style="color:rgb(194, 39, 47)" href="https://amenedistribution.com/">Accueil</a></span></li>
<li class="bullet-arrow"><span style="font-size: 90%;"><a style="rgb(235, 93, 39)" href="https://amenedistribution.com/#propos">A propos de nous</a></span></li>
<li class="bullet-arrow"><span style="font-size: 90%;"><a style="color:rgb(194, 39, 47)" href="https://amenedistribution.com/#produits">Nos produits</a></span></li>
<li class="bullet-arrow"><span style="font-size: 90%;"><a style="rgb(235, 93, 39)" href="https://amenedistribution.com/#contact">Contact</a></span></li>
</ul>
  

</div>


  </div>
        </div>



</div> */}
    {/* <div className='container py-5'>
      <Row>
        <Col>
        <h5 className='white-text mx-4'>[ Categories ]</h5>
        <ul className='white-text mx-2' >
         <li><i className='me-2 fa fa-angle-right orange-text'></i>Freins </li> 
         <li><i className='me-2 fa fa-angle-right orange-text'></i>Filtre</li>
         <li><i className='me-2 fa fa-angle-right orange-text'></i>Suspension</li>
         <li><i className='me-2 fa fa-angle-right orange-text'></i>Carrosserie</li>
         <li><i className='me-2 fa fa-angle-right orange-text'></i>Moteur</li>
         <li><i className='me-2 fa fa-angle-right orange-text'></i>Commande à courroie / chaîne</li>
         <li><i className='me-2 fa fa-angle-right orange-text'></i>Amortissement</li>
         <li><i className='me-2 fa fa-angle-right orange-text'></i>Système d'essuie-glaces</li>
         <li><i className='me-2 fa fa-angle-right orange-text'></i>Refroidissement moteur</li>
        </ul>
        </Col>
      
        <Col>
        <h5 className='white-text mx-4'>[ Marques ]</h5>
        <ul className='white-text mx-2' >
        <li onClick={()=>setmarque('ISUZU')}><i className='me-2 fa fa-check orange-text'></i>
          <a>Isuzu</a></li>
        <li onClick={()=>setmarque('NISSAN')}><i className='me-2 fa fa-check orange-text'></i>Nissan</li>
        <li onClick={()=>setmarque('MAZDA')}><i className='me-2 fa fa-check orange-text'></i>Mazda</li>
        <li><i className='me-2 fa fa-check orange-text'></i>Kia</li>
        <li><i className='me-2 fa fa-check orange-text'></i>Mahindra</li>
        <li onClick={()=>setmarque('TOYOTA')}><i className='me-2 fa fa-check orange-text'></i>Toyota</li>
        <li onClick={()=>setmarque('FORD')}><i className='me-2 fa fa-check orange-text'></i>Ford</li>
        </ul>
        </Col>
        <Col>
        <h5 className='white-text mx-4'> [ Amen Distribution ]</h5>
        <ul className='white-text mx-2' >
        <li><i className='me-2 fa fa-angle-right orange-text'></i>A propos</li>
        <li><i className='me-2 fa fa-angle-right orange-text'></i>Contact</li>
        <li><i className='me-2 fa fa-angle-right orange-text'></i>Nos services</li>
        <li><i className='me-2 fa fa-angle-right orange-text'></i>Livraison</li>
        </ul>
        </Col>
        <Col>
        <h5 className='white-text mx-4'>[ Centre d'aide ]</h5>
        <ul className='white-text mx-2' >
         <li >Comment ca marche? </li> 
         <li>Retour</li>
         <li>Garantie</li>
         <li>Service après vente</li>
        </ul>
        </Col>
      </Row>
    
      
    </div> */}
    <Row className='mt-5'>
     <div class=" just text-center ">
     © 2024 Amene Distribution | All rights reserved | 
     <a class="text-reset fw-bold"  href='https://www.businessoftware.com.tn'> BUSINESS SOFTWARE</a>
   </div>
   </Row>
   </div>
  );
}

export default connect(
  (state) => ({ products: state.products.filteredItems , }),
  {
    
    clearProducts
  }
)(Footer);
