import React from 'react'
import {fetchCom } from '../actions/orderActions'
import { connect } from 'react-redux'
import {  MDBCard,
 
  MDBIcon,
   MDBBadge ,
   MDBBtn,
   MDBTable,
   MDBTableBody,
   MDBTableHead,MDBRow  
   } from 'mdbreact';
   import formatCurrency from "../util";


function ExpandRow(props) {
    
    return (
        <div   > 
        {props.com ?(
   <MDBRow center >     
 <MDBTable striped  responsive >
 <MDBTableHead>
   <tr>
    
     <th>Ref.</th>
     <th>Designation </th>
     <th> Qte.</th>

     <th>P.U.H.T</th>
     <th>TVA</th>
     <th>Net H.T </th>
     <th>P.U.TTC</th>
     
   </tr>
 </MDBTableHead>

 <MDBTableBody>
 { props.com.map((item) => (  
   <tr key={item.nf}>
     
     <td>{item.Codart}</td>
     <td>{item.libart}</td>
     <td>  {item.qt}</td>

     <td>{formatCurrency(item.puht)}</td>
     <td>{item.mnttva}</td>

     <td>{formatCurrency( item.mntht )} </td>
     <td>{formatCurrency(item.puttc/item.qt)}</td>


    
   </tr>
   ))}  
   <tr>
     <td />
    

     <td>
       <strong>Total H.T </strong>
     </td>
    
     <td>
       <strong> {props.com&&(formatCurrency(props.com.reduce((a, c) => a + c.puht*c.qt, 0)))} </strong>
     </td>
     
   </tr>
   <tr>
     <td />
   
     <td>
       <strong>Total TTC </strong>
     </td>
    
     <td>
       <strong> {props.com&&(formatCurrency(props.com.reduce((a, c) => a + c.puttc, 0)))} </strong>
     </td>
   </tr>
 </MDBTableBody>
</MDBTable>
</MDBRow>  ):(<span>not commandes </span>)}
      </div>
    )
}

export default connect(
    (state) => ({

      com:state.order.com
    }),
    {
        fetchCom
     
    }

) (ExpandRow)

/*     <td>{ formatCurrency(item.puttc/item.qt)  } </td>*/
