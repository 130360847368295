import React, { useState, useEffect } from 'react';
import { network } from "../constants";
import './Navbar.css'
import Dropdown from 'react-bootstrap/Dropdown';

import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBEdgeHeader, MDBBadge, MDBIcon, MDBAlert } from 'mdbreact';
import image from '../assets/Log1.png'
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import store from "../store";
import { clearProducts } from "../actions/productActions";
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { setCurrentUser, logoutUser } from '../actions/loginAction';
import { Button, ButtonGroup, Modal, Form, Row, Col, Card } from '@themesberg/react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead'
const Nav = (props) => {
    // console.log(props)
  const [Listmarque, setListmarque] = useState([]);
  const [Listmodel, setListmodel] = useState([]);
  const [model, setmodel] = useState([]);
  const [Societe, setSociete] = useState([]);
  const [search, setsearch] = React.useState('');
  const [fam, setfam] = useState('%');
  const [marque, setmarque] = useState([]);
  const [item, setitem] = useState([]);
  const [modele, setmodele] = useState('%');
  var lang = props.cartItems.length;
  const user = localStorage.getItem("jwtname")

  useEffect(() => {

    fetch(`${network.serverip}/ListMarque`).then(res => res.json()).then(dat => { setListmarque(dat); setitem(dat.filter(x => x.label === props.marque)) })

    if (props.marque != '') {
      //  setitem(Listmarque.filter(x => x.label === props.marque))
      fetch(`${network.serverip}/ListSoufam/${props.marque}`).then(res => res.json()).then(data => { setListmodel(data); setmodel(data.filter(x => x.label === props.model)) })
    }
    else {
      fetch(`${network.serverip}/ListSoufam`).then(res => res.json()).then(dat => setListmodel(dat))
    }

   setsearch(props.query)


  }, [])

  // useEffect(()=>{
  //  )
  //   setmodel(Listmodel.filter(x => x.label === props.model))

  // },[Listmarque,Listmodel])

  useEffect(() => {
    fetch(`${network.serverip}/Societe`).then(res => res.json()).then(data => { setSociete(data) })
  }, [])

  const listeModeles = (marque) => {
    console.log(marque)
    if (marque.length > 0)
      fetch(`${network.serverip}/ListSoufam/${marque[0]['label']}`).then(res => res.json()).then(data => { setListmodel(data) })

  }
  let history = useHistory();

  const handlesearch2 = (e) => {
    
      // console.log(props.query)
    // if (search.length > 3) {
    // const marque = item.length > 0 ? item[0]['label'] : '%'
    // const modele = model.length > 0 ? model[0]['label'] : '%'
    //  props.clearProducts()
       history.push({
        pathname: '/produits',
        search: `?${props.query}`,
        state: { detail: props.query}
       })
    //  location.reload();
  



  }
  const handlesearch = (e) => {

    const marque = item.length > 0 ? item[0]['label'] : '%'
    const modele = model.length > 0 ? model[0]['label'] : '%'
    props.clearProducts()
   if(item.length > 0) 
    {
      history.push({
        pathname: '/ProdFilter',
        search: `?${fam, modele, marque, search}`,
        state: { fam, modele, marque, search }
      })
      location.reload();
    }
   
  }





  return (
    <div >
      
     

      <nav class=" navbar-dark bg-dark ">
        {Societe.length > 0 && window.innerWidth > 1000 &&

          <ul className='societe d-flex'>
            <li className='me-3 white-text' style={{ fontSize: "15px" }}><i className='me-2 fa fa-envelope orange-text'></i><small>{Societe[0].EMAIL}</small></li>
            <li className='me-3 white-text' style={{ fontSize: "15px" }}><i className='me-2 fa fa-phone orange-text'></i><small>(+216) {Societe[0].TELEPHONE}  /</small> <small>  (+216) 76 670 073 </small></li>


            <li className='me-3 white-text'></li>
            <li className='me-3 white-text' style={{ fontSize: "15px" }}><i className='me-2 fa fa-home orange-text'></i><small>{Societe[0].ADRESSE}</small></li>



          </ul>

        }
      </nav>

      <div className='container justify-content-between d-flex flex-auto'>
        <div className='Nav d-flex'>
          <div >
            <a href="/"><img className='me-5' src={image}
              style={{width:window.innerWidth > 1000 ? 150:"90%", height: 60 }}
            /></a>
          </div>

          <div className='d-flex' style={{marginTop:window.innerWidth > 1000 ?0: 50}}>
            <input
              className='form-control   my-1 py-0 '
              type='text'
              style={{ width: window.width > 1000 ? '500px' : '300px' }}
              placeholder='Rechercher '
              id="formGroupExampleInput"
              aria-label='Search'
              // onChange={(e) => {
              //   e.preventDefault();
              //   setsearch(e.target.value)


              // }}
             
              onChange={props.handleInputChange}
              value={props.query}
            />
             <MDBBtn className='white-text h-35 ' type="submit"
              style={{ height: 35 }}
              onClick={() => handlesearch2()}


              color="red"><i className="fas fa-search "></i></MDBBtn> 
          </div>
        



        <ul className='d-flex' style={{marginTop:window.innerWidth > 1000 ?0: -90}}>

          <li className=' d-flex' ><i className='mt-2 fas fa-user-circle fa-x' ></i><Dropdown>
            <Dropdown.Toggle variant="link" id="dropdown-basic" style={{ color: 'black' }}>{user}

            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Information</Dropdown.Item>
              <Dropdown.Item href="/Orders" onClick={() => store.dispatch(fetchOrders())}>Commandes</Dropdown.Item>
              <Dropdown.Item href="#/action-3" onClick={() => props.logoutUser()}>Déconnecter</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown></li>
          <li > <Link to={'/Cart'} ><i className=' mt-2  fas "fa fa-shopping-cart fa-x' ></i><span>
            <MDBBadge pill color='red' style={{ fontSize: '9px', position: 'absolute' }}  >{lang}</MDBBadge>
          </span></Link></li>
        </ul>
        </div>



      </div>
      {/* <hr /> */}
      <div className=' bg-light just my-5'>
        <div className='container my-2 ' >
          <h3 className="h3-responsive font-weight-bold   bg-light p-3  ">
            Sélectionner votre voiture
          </h3>
          <Row >


            <Col className='mb-3 my-2' md={3}>
              <Form.Group className="d-flex" id="gender"  >
                <h5 className='mt-2 me-3'>Marque  </h5>
                <Typeahead

                  defaultValue={item}
                  onChange={(t) => { setitem(t); listeModeles(t); setmodel([]) }}
                  options={Listmarque}
                  placeholder="----"
                  selected={item}

                />
           

              </Form.Group>
            </Col>
            <Col className='mb-3 my-2' md={3}>
              <Form.Group className="d-flex" id="gender"  >  <h5 className='mt-2 me-3'>Modéle  </h5>
                <Typeahead

                  defaultValue={model}
                  onChange={(t) => { setmodel(t); }}
                  options={Listmodel}
                  placeholder="----"
                  selected={model}

                />
              </Form.Group>
            </Col>
            <Col className='my-1' md={3}>

              <MDBBtn className=" white-text" type="submit" size="sm" color="red"
                style={{ width: window.innerWidth > 1000 ? '150px' : '300px' }}
                onClick={() => handlesearch()}

              >Chercher </MDBBtn>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    products: state.products.filteredItems,
    cartItems: state.cart.cartItems,
  }),
  {

    clearProducts,
    logoutUser,
  }
)(Nav);
