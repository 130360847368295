import React from "react";
import { MDBCarousel, MDBBtn ,MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer } from
"mdbreact";

const CarouselPage = () => {
  return (
    <MDBContainer>
      <MDBCarousel
      activeItem={1}
      length={2}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
      style={{height:'220px'}}
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
          <MDBView>
            <img
              className="d-block w-100"
              src={image1}
             style={{height:'320px' ,width:'420px'}}
              alt="First slide"
            />
          <MDBMask overlay="black-light" />
          </MDBView>
          <MDBCarouselCaption>
            <h1 className="h1-responsive "   >Vente Pièces et Accessoires Automobiles</h1>
            <MDBBtn  color='success' > Savoir plus </MDBBtn>

          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
          <MDBView>
            <img
              className="d-block w-100"
              src={image2}
              alt="Second slide"
            />                                                                                                                                                                                                                   
          <MDBMask overlay="black-light" />
          </MDBView>
          <MDBCarouselCaption>
            <h3 className="h1-responsive">Vente Pièces et Accessoires Automobiles</h3>
            <MDBBtn  color='success'  > Savoir plus </MDBBtn>

          </MDBCarouselCaption>
        </MDBCarouselItem>
        
      </MDBCarouselInner>
    </MDBCarousel>
    </MDBContainer>
  );
}

export default CarouselPage;