import React, { useEffect,useState } from 'react'
import ScrollToTop from "react-scroll-to-top";
import Spinner from 'react-bootstrap/Spinner'; 
import { network } from "../constants";
import './Navbar.css';
import {
  MDBEdgeHeader, MDBContainer,
  MDBCol,
  MDBRow, MDBCard, MDBBadge, MDBAnimation, MDBInput, MDBAlert, MDBModalBody, MDBModal, MDBModalHeader, MDBPagination, MDBPageItem, MDBPageNav, MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBCardHeader, MDBCardFooter,


} from 'mdbreact';
import axios from 'axios';
import ReactPaginate from "react-paginate";
import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import './HomePage.css';

import { Button, Dropdown, ButtonGroup, Modal, Form, Row, Col, Card } from '@themesberg/react-bootstrap';


import { connect, useDispatch } from "react-redux";
import {  FilterProd, clearProducts } from "../actions/productActions";
import { addToCart, removeFromCart } from "../actions/cartActions";
import { fetchUser, setCurrentUser ,logoutUser} from "../actions/loginAction";
import { fetchImg, fetchImageProd } from "../actions/imageAction";


import spinner from './spinner.gif';

import { useLocation } from "react-router-dom";

import Nav from './Nav';
import Footer from '../Footer';


function ProdFilter(props) {

  const location = useLocation();


  const [currentPageData, setCurrentPageData] = React.useState([]);
 
  const [data, setdata] = React.useState([]);

  const { cartItems } = props;

  const [marque, setmarque] = React.useState("");
 
  //var { products } = useSelector((state) => state.products);
  const [products, setproducts] = React.useState([]);
  const [Allproducts, setAllproducts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = React.useState(0);
  const [itemOffset, setItemOffset] = React.useState(0);
  const [query, setQuery] = useState("");
  const articleParPage = 12;
  const [loaded, setLoaded] = useState(false);

  const handleImageLoaded = () => {
    setLoaded(true);
  };
  // useEffect(() => {
  //   const checkSession = async () => {
     
  //     try {
  //     await axios.get(`${network.serverip}/protected`, { withCredentials: true });
     
  
  //     } catch (error) {
  //       setIsSessionValid(false);
      // setShowModal(true);
  //     }
  //   };

  //   checkSession();
  // },[])

  useEffect(() => {


    setmarque(location.state.marque)
    setLoading(true);
    props.clearProducts()
    fetch(`${network.serverip}/filter`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(location.state),


    }).then((res) => res.json())
      .then((data) => {

        setItemOffset(0)
        setproducts(data.sort((a, b) => (a.LibArt < b.LibArt ? -1 : 1)));
        setAllproducts(data.sort((a, b) => (a.LibArt < b.LibArt ? -1 : 1)));
        setdata(data.sort((a, b) => (a.LibArt < b.LibArt ? -1 : 1)))
        setLoading(false);

      })


       if (!localStorage.getItem('jwtname')) {
        props.history.push('/login');
      }
    if (props.products) {
      setLoad(false); setAlert(true)

    }

    if (props.error) {
      setLoad(false)
    }

  }, [location])

  useEffect(() => {
   
    const endOffset = itemOffset + articleParPage;
    setCurrentPageData(products.slice(itemOffset, endOffset));

    setPageCount(Math.ceil(products.length / articleParPage));
  }, [products, itemOffset, articleParPage, location,query]);
 
  useEffect(() => {
    filter();
  }, [query]);

  
  const handlePageClick = (event) => {


    const newOffset = (event.selected * articleParPage) % products.length;
    setItemOffset(newOffset);
  };

  const filter = () => {
  
    const keyword = query;
    if (keyword !== "") {
      const results = Allproducts.filter((product) => {
        return ( product?.LibArt.toLowerCase().includes(keyword.toLowerCase()) || product?.Codart.toLowerCase().includes(keyword.toLowerCase()) )
          // 
        
        
    // .join("")
    // .toLowerCase()
    // .includes(keyword.toLowerCase())
    
        // return product?.LibArt.toLowerCase().includes(keyword.toLowerCase());
      });
    //  console.log(results)
      setproducts(results);
       setItemOffset(0);
      // console.log(results)
    } else {
      setproducts(Allproducts);
        setItemOffset(0); 
    }
  };

  const [id, setid] = React.useState(1);
  const [Count, setcount] = React.useState(0);
  const [COT, setCOT] = React.useState(false);
  const [Load, setLoad] = React.useState(false);

  const [produit, setProduct] = React.useState(false);


  const [Alert, setAlert] = React.useState(false);
  const [modal, setmodal] = React.useState(false);













  const handleClick = (product, count) => {


    props.addToCart(product, count);

    setCOT(false)

  }






  const handleZoom = (pro) => {

    setProduct(pro);
    setmodal(true)
  }


  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const [isSessionValid, setIsSessionValid] = useState(true);
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleRedirectToLogin = () => {
    localStorage.clear(); // Clear all local storage items
    window.location.href = '/login'; // Redirect to login page
  };

  return (
    <>

      <div >

      {/* <div>
        <Modal 
        backdrop={false}
         className='modal'
         dialogClassName='modal-container'
         aria-labelledby="contained-modal-title-vcenter"
         size='lg'
        show={showModal} 
        // onHide={handleModalClose}
        >
        <Modal.Header closeButton>
          <Modal.Title>Session Expired</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Votre session a expiré! Veuillez vous reconnecter.
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" onClick={()=>props.logoutUser()}>
            Se reconnecter
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
        */}
        <Nav marque={location.state.marque} model={location.state.modele} query={query} handleInputChange={handleInputChange}/>
        <ScrollToTop className='mb-4' smooth viewBox="0 0 24 22" svgPath="M18 15l-6-6-6 6" />
      
            {loading && (

              <div className='container justify-content-center d-flex'>
                <img

                  src={spinner}
                  style={{ justifycontent: 'center', width: '200px', display: 'block', float: 'center' }}
                  alt=""
                />
                {/* <h3 >Loading...</h3> */}
              </div>)}


        {props.error && (<MDBRow className='mt-2' center>

          < MDBAlert color="danger" size='md'>
            <h5> <MDBIcon icon="info-circle" className='danger-text' /> aucun article n'a été trouvé</h5>
          </MDBAlert>


        </MDBRow>)}

        <MDBRow center>


          <MDBModal centered isOpen={modal} size='md'  >
            <MDBModalHeader toggle={() => setmodal(false)} >  {produit.Codart} </MDBModalHeader>

            <MDBRow className='justify-content-center mx-2' >

              {/* <Img product={image.find(X => X.IDArt === produit.IDArt)?.imgArt || ''} /> */}
              <img src={produit.ExLibArt} />

            </MDBRow>

            <MDBModalBody>
            </MDBModalBody>

          </MDBModal>



        </MDBRow>






        {products &&

          <MDBContainer >

            {/* <MDBRow className='pt-3' end>
  

  
  <button type="button" class="btn btn-link dropdown-toggle" data-mdb-toggle="dropdown" aria-expanded="false">
    Tri Par
  </button>
  <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="#" on onClick={ascorder()}>A-z</a></li>
    <li><a class="dropdown-item" href="#">z-a</a></li>
    <li><a class="dropdown-item" href="#">Something else here</a></li>
    <li><hr class="dropdown-divider" /></li>
    <li><a class="dropdown-item" href="#">Separated link</a></li>
  </ul>

  </MDBRow> */}
            
             

                <MDBRow className='row-cols-md-2 row-cols-md-4 d-flex'  >



                  {currentPageData.map((row, i) => (
                    <MDBCol >

                      <MDBCard style={{ marginTop: 10, borderWidth: 2 ,width:"320px"}} key={row.Codart}

                        border={props.cartItems.find(x => x.IDArt == row.IDArt) && "success"}    >


                        {/* <MDBAnimation reveal type='fadeInLeft'> */}
                        <MDBContainer   >
                          <MDBRow className='mb-2' around>
                            <MDBCol >
                              <img src={row.Urlimg} style={{ width: '100px', height: '70px' }} /> </MDBCol>
                            <MDBCol className='mt-2'><strong style={{
                              fontSize: '0.8em',
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }} >  {row.Codart} </strong></MDBCol>

                          </MDBRow >

                          <MDBRow center onClick={() => { handleZoom(row) }}  >



                            {/* <Img product={image.find(X => X.IDArt === row.IDArt)?.imgArt }
                                  style={{ width: '150px', height: '8em' }}
                                   />
                                    */}
                                     {!loaded && (
        <Spinner
          animation="border"
          variant="primary"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}

                            <img src={row.ExLibArt}
                              // "https://i.ibb.co/pjCcq4M/amen1.jpg"
                              style={{display: loaded ? 'block' : 'none', width: '15em', height: '15em' }}
                              
                              onLoad={handleImageLoaded}
                              />

 




                          </MDBRow>

                          <MDBRow center>

                            <div className='text' style={{
                              textDecoration: 'underline', fontSize: "0.75em", marginTop: "10px"
                              // , textOverflow: "ellipsis",
                              //  overflow: "hidden", 
                              //  whiteSpace: "nowrap" 
                            }} center><strong >{row.LibArt}</strong></div>

                          </MDBRow>
                          <MDBRow>
                            <MDBCol className='mt-2' >      <p > {row.Qte > 0 ? (<MDBBadge color="success"> En Stock </MDBBadge>) : (<MDBBadge color="danger"> En Rupture  </MDBBadge>)} </p> </MDBCol>

                            <MDBCol><strong > <p className='text-center ' style={{ marginTop: 10 }}>{row.PrixVente.toFixed(3)} <sup>TND</sup> </p> </strong></MDBCol>
                          </MDBRow>

                        </MDBContainer>

                        {cartItems.find(x => x.IDArt == row.IDArt) ?
                          <MDBRow className='justify-content-center'>



                            <MDBBtn outline floating tag='a'
                              color='red'
                              onClick={() => {

                                handleClick(row, -1);
                                // if(produit.Qt<=Count)return;
                              }}>
                              <MDBIcon className='red-text py-1 px-1' icon='minus' size='2x' color='red' />
                            </MDBBtn>




                            <input
                              name={row.Codart}
                              type="text"
                              className="d-flex mt-2"
                              min={1}
                              max={row.Qte}
                              id={row.IDArt}
                              style={{ width: '70px', height: '99%' }}
                              value={cartItems.find(X => X.Codart === row.Codart)?.count || 0}
                              onMouseEnter={(e) => setid(e.target.name)}
                              onKeyDown={(e) => { e.key === 'Enter' & row.Qte > 0 ? (handleClick(row)) : (null) }}
                              // onChange={(e) => { setcount(Number(e.target.value)); setid(row.Codart); console.log(Count) }}
                              onChange={(e) => { handleClick(row, Number(e.target.value)) }}
                              onLoad={e => e.target.value}
                            />
                            <MDBBtn className='me-3' outline floating tag='a'
                              color='red'
                              onClick={() => {

                                handleClick(row, 1);
                                // if(produit.Qt<=Count)return;
                              }}>
                              <MDBIcon className='red-text py-1 px-1' icon='plus' size='2x' color='red' />
                            </MDBBtn>
                            <MDBBtn outline floating tag='a' color='white'
                              onClick={() => {
                                props.removeFromCart(row);
                              }}>
                              <MDBIcon className='black-text py-1 px-1' icon='trash-alt' size='2x' color='primary' />
                            </MDBBtn>







                          </MDBRow> : (<MDBRow center ><MDBBtn className='white-text' style={{ width: '60%', borderRadius: '10px' }} size='sm' color='red'

                            onClick={() => { setcount(prevCount => prevCount + 1); handleClick(row, 1) }}  >
                            <MDBIcon fas icon="cart-plus" /> Ajouter au panier</MDBBtn> </MDBRow>)}


                        <MDBRow >




                          <MDBCol className='justify-content-center' size='6'  >
                            {/* <Img product={product.imgMarque} style={{ width: '80%', height: '50px' }} />
                                      <hr className='my-2' />
                                      <MDBRow center>
                                        <p className='mt-2' >  {product.Codart} </p>
                                           
                                        </MDBRow >
                                        <hr className='my-2' /> */}
                            {row.Qt > 1 && <MDBRow around className='mt-2' >





                              {COT & id === row.Codart ? (<MDBRow center>


                                <label className='red-text ' style={{ marginTop: 10, fontSize: '15px' }} ><MDBIcon icon="info-circle" className='red-text ' />  Qte.invalide </label>



                              </MDBRow>) : (null)}





                            </MDBRow>}




                          </MDBCol>
                        </MDBRow>

                        {/* </MDBAnimation> */}

                        {/* <MDBCardFooter className='mt-2 text-center'>

                          <MDBRow center> </MDBRow>*/}



                      </MDBCard>
                    </MDBCol>


                  ))}
                </MDBRow >

                <MDBRow>

                </MDBRow>

                  

                  <div style={{maxWidth:"100%"}}>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={2}
                      pageCount={pageCount}
                      previousLabel="<"
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      pageLinkClassName="page-num"
                      previousLinkClassName="page-num"
                      nextLinkClassName="page-num"
                      activeLinkClassName="active"
                      
                    />
                  </div>

                
                  

             

            






          

          </MDBContainer>

        }















      </div>
      <hr />
      <Footer />
    </>
  );
}

export default connect(
  (state) => ({

    error: state.products.error,
    cartItems: state.cart.cartItems,
    user: state.pass.user,
    isAuthenticated: state.pass.isAuthenticated,
    loading: state.products.loading,
    images: state.image.img,
    imgart: state.image.imgItems
  }),
  {
    fetchImg, fetchImageProd,
    addToCart, removeFromCart,
    setCurrentUser,
    logoutUser,
    fetchUser, FilterProd, clearProducts
  }
)(ProdFilter)



//slice(offset*12, offset*12 + 12).