import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {

  MDBFooter,MDBBtn,
  MDBNavLink,MDBBadge ,
   MDBModal, MDBModalBody, MDBModalHeader, MDBDropdown,
   MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon 
} from 'mdbreact';
import { BrowserRouter as Router,Link } from 'react-router-dom';

import Rout from './Route';


import Cart from './comp/Cart'
class App extends Component {


constructor(props){
   super(props)
   this.state = {
    collapseID: '',
    modal:false ,
    langeur:0
  };


}

  
  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }));

  closeCollapse = collID => () => {
    const { collapseID } = this.state;
    window.scrollTo(0, 0);

    collapseID === collID && this.setState({ collapseID: '' });
  };

  render() {
    const overlay = (
      <div
        id='sidenav-overlay'
        style={{ backgroundColor: 'transparent' }}
        onClick={this.toggleCollapse('mainNavbarCollapse')}
      />
    );
  
    const { collapseID ,modal,cartItems,langeur } = this.state;
 



 
    return (
      <div>
          

      <Router>
      <ToastContainer/>
        <div className='flyout'>
         
          {collapseID && overlay}
          <main >
       
            <Rout />
          </main>
      
        </div>
      </Router>


       <MDBModal isOpen={this.state.modal}  toggle={()=>this.setState({
          [modal]: !this.state[modal]
                      })} >
          <MDBModalBody>
          <Cart/>
          </MDBModalBody>
         </MDBModal>
      </div>
    );
  }
}

export default  (App);
