import React from 'react';
import {
  MDBEdgeHeader,  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCardBody,
  MDBIcon,
  
  MDBBtn
 
} from 'mdbreact';
import './HomePage.css';
 


class HomePage extends React.Component {
  scrollToTop = () => window.scrollTo(0, 0);

  render() {
    return (
      <>
        <MDBEdgeHeader color='indigo darken-3 z-depth-2' className='sectionPage'  >  
                
         </MDBEdgeHeader>
        <div className='mt-3 mb-5'>

            <MDBRow > 
            <MDBCol
                md='8' 
                className='mx-auto cascade  float-none  py-0 px-2 '
                style= {{marginTop:-100}}
              >  
              
              <h1 className="text-center my-5 font-weight-bold  py-0 font-weight-bold "   style= {{marginTop:20, color:'white'}}>WELLCAME</h1>
              
              </MDBCol>

              <MDBCol
                md='8' 
                className='mx-auto cascade  float-none grey lighten-4 z-depth-1 py-0 px-2 '
              >  

                <MDBCardBody cascade className='text-center'>
                   <MDBRow>
                  
                   <MDBCol></MDBCol>
                   <div className='input-group md-form form-lg form-6 pl-2'>
                    <div className='input-group-prepend'>
                      <span
                        className='input-group-text default '
                        id='basic-text1'
                      >
                    
                      </span>
                    </div>
                   
                    <input
                      className='form-control my-2 py-1'
                      type='text'
                      style={{ height: 40}}
                      placeholder='Search'
                      aria-label='Search'
                    />
                    <MDBBtn size="sm" floating gradient="blue" rounded>
                       <MDBIcon icon="search" />
                      Search
                    </MDBBtn>

                  </div>
                 
                    
                  
                  </MDBRow>
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
         
          <MDBContainer>
            <MDBRow>
              <MDBCol md='12' className='mt-2'>
                <h2 className='text-center my-5 font-weight-bold'>
                  WELLCAME  !!
                </h2>
                <p className='text-center text-muted mb-1'>
                  application  pour vendre des pieces de rechenges
                </p>
                
                <hr className='my-2' />

                
                <MDBRow>
                 <Produit/>

                </MDBRow>
                <MDBRow>
                 <Produit/>

                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </>
    );
  }
}

export default HomePage;
