import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBEdgeHeader, MDBIcon, MDBAlert } from 'mdbreact';
import { MDBInput } from 'mdb-react-ui-kit';
import { fetchUser, setCurrentUser } from "../actions/loginAction";
import { connect } from "react-redux";
import './login.css';
import image from '../assets/Log1.png'
import isEmpty from '../validation/is-empty';

const Login = (props) => {

  React.useEffect(() => {
    if (props.error) { setAlert(true) }
  })





  const [nom, setnom] = React.useState("");
  const [errors, seterrors] = React.useState("");
  const [password, setpassword] = React.useState("");
  const [Alert, setAlert] = React.useState(false);

  const { ...rest } = props;




  const fetchUser = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";


   

      return props.fetchUser(nom, password, props.history)
    




  }
  return (

    <div className='bodyLogin' >
      <form
        className="needs-validation"
        onSubmit={e => fetchUser(e)}
        noValidate
      >

        
          <MDBRow around  style={{marginTop:'10%'}} >
            <MDBCol md='5' ms='12' ></MDBCol>
            <MDBCol md='4' ms='10'  >
           
              
                
                    
                 
                  <div className="mx-4" >
                  <img src={image} style={{ width: 250, height: 100 }} />
                 
                  <h3 className='black-text mx-5 mt-5'>
                 <strong  > Votre Espace B2B   </strong>

                   </h3>
               <p className="mx-4 mb-5" style={{fontSize:'10px',letterSpacing:'0.2em'}}> Connectez-vous en entrant votre compte ici </p>



                
                  <div className='col-md-8' >
                  <MDBInput 
                    label="Nom d'utilisateur"
                    onChange={e => { setnom(e.target.value); setAlert(false) }}
                    id="defaultFormRegisterNameEx"
                  
                    maxWidth='60%'
                    type='text'
                    value={nom}
                    
                    error="error"
                  
                    style={{   maxWidth: '70%' }}
                    required
                  />
                  <br />
                  <MDBInput
                   
                    label='Mot de passe'
                    value={password}
                    group
                    id="defaultFormRegisterEmailEx2"
                   
                    type='password'

                    style={{ maxWidth: '70%' }}
                    
                    onChange={e => setpassword(e.target.value)}
                    required
                  />
                  <MDBBtn className="col-md-11 mt-4 mb-2 white-text"
                   type="submit"
                   color="red">Se connecter </MDBBtn>
</div>
                  <p className="mt-2 mx-4" style={{fontSize:'8px',letterSpacing:'0.2em',marginTop:'30px'}}>vous n'arrivez pas à vous connecter ? <strong style={{textDecoration: "underline"}}>Contactez-nous</strong> </p>
                  </div>
            

                  <div className='md-form pb-1'>

                  </div>
                  {/* <MDBRow className='d-flex align-items-center ' center >

                    {Alert && (< MDBAlert color="danger"  >
                      <MDBIcon icon="info-circle" className='danger-text' /> {props.error}
                    </MDBAlert>)}
                    <div className=' mb-0 col-md-8 ' >
                      <MDBBtn
                       
                      
                        className=' z-depth-1'
                        type="submit"

                      >


                        <MDBIcon icon="user-circle" size='x' className=' mx-2' />  
                      </MDBBtn>
                    </div>

                  </MDBRow> */}

                
            
            </MDBCol>
          </MDBRow>
       
      </form>
    </div>
  );
};

export default connect(
  (state) => ({
    isAuthenticated: state.pass.isAuthenticated, user: state.pass.user, error: state.pass.error



  }),

  {
    fetchUser,
    setCurrentUser
  }
)(Login);