import { CREATE_ORDER, CLEAR_CART, CLEAR_ORDER, FETCH_ORDERS,FETCH_COM } from "../types";
import {  network } from "../constants";
export const createOrder = (order ) => (dispatch) => {

console.log(order);
  fetch(`${network.serverip}/band`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(order),
    
    
  })
   
};
export const clearOrder = () => (dispatch) => {
  dispatch({ type: CLEAR_ORDER });
};

export const fetchOrders = ( ) => async (dispatch) => {
  const code=localStorage.getItem("code")
  const niveau=localStorage.getItem("niveau")

  if(niveau==2){
    const res = await  fetch(`${network.serverip}/ordersCom/${code}`);
    const data = await res.json();
    dispatch({
      type: FETCH_ORDERS,
      payload: data,
    });
  }else{
    const res = await  fetch(`${network.serverip}/orders/${code}`);
    const data = await res.json();
    dispatch({
      type: FETCH_ORDERS,
      payload: data,
    });
  }
  
};
export const fetchCom = (id) => async (dispatch) => {

  const res = await fetch(`${network.serverip}/Com/${id}`); 
  const data = await res.json();
  console.log(data);
  dispatch({
    type: FETCH_COM,
    payload:data,
  });


};

