import React,{useEffect} from 'react';
import { network } from "../constants";
import { connect, useSelector } from 'react-redux'
import {  fetchCom } from '../actions/orderActions'
import {  MDBCard,
    MDBCardTitle,
    MDBContainer, 
    MDBCardBody,
    MDBCardHeader,
    MDBNotification,
    MDBIcon,MDBModalHeader,
     MDBCol,
     MDBRow,
     MDBBadge ,
     MDBBtn,
     MDBAlert,
     MDBTable,
     MDBTableBody,
      MDBAnimation,MDBModal,MDBModalBody,MDBCardFooter,
     MDBTableHead, 
     MDBModalFooter} from 'mdbreact';
const DetailsCmd = (props) => {
 
   
    const [com, setcom] = React.useState([]);
    const [comm, setcomm] = React.useState([]);
    useEffect(() => {
      
        fetch(`${network.serverip}/ligCom/${props.row}`).then(res => res.json()).then(dat => { setcom(dat)})
        fetch(`${network.serverip}/Com/${props.row}`).then(res => res.json()).then(data => { setcomm(data)})   
            },[])
           
       console.log(comm)   
   
  return (
    <div className='mx-3' >
     {comm.length>0 &&
     <>
      <p><strong>Code :  </strong>  {comm[0].CodTiers}</p>
     <p><strong>Raison Sociale :  </strong>  {comm[0].LibTiers}</p>
     </> 
     }
       <MDBTable >
                <MDBTableHead>
                  <tr>
                    <th scope='col'><strong>Ref.</strong></th>
                    <th scope='col'><strong>Libelle</strong></th>
                    <th colSpan={2}><strong>Qte.cmd</strong> </th>
                   
                    <th>P.U.H.T</th>
                    <th>Remise</th>
                    <th>Net H.T </th>
                    <th>TVA</th>
                     <th>P.U.TTC</th>
                     {/* <th colSpan={2}><strong>Qte.Liv</strong> </th>     */}
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                      {com && com.map((item)=>(
                         <tr >
                    
                         <td >{item.Codart}</td>
                         <td colSpan={2}>{ item.libart } </td>
                         <td align='center' ><strong className='red-text center'>{ item.qt }</strong></td>
                         <td align='right'>{item.puht.toFixed(3)}</td>
                         <td>{item.remise}</td>
                         <td align='right'>{ item.MntHT.toFixed(3) } </td>
                        <td align='right'>{item.MntTVA}</td>

                       
                         <td>{(item.puttc/item.qt).toFixed(3)}</td>
                         {/* <td align='center'><strong className='green-text center'>{ item.Liv }</strong></td> */}
                         </tr>
                    ))}   
                    <tr><td /></tr>
                    { comm.length>0 && <>
                  <tr style={{backgroundColor:"#e7e7e7"}}>
                    <td />
                    <td align='right'>
                      <strong>Brut.HT </strong>
                    </td><td align='left' style={{color: '#0000CD'}}> 
                    {comm[0].MntDebit.toFixed(3)}
                    </td>
                    <td>
                      <strong>Remise </strong>
                    </td><td style={{color: '#0000CD'}}>
                    {comm[0].TotRem.toFixed(3)}
                       </td>
                    <td>
                      <strong>Tot.HT </strong>
                    </td>
                    <td  >
                    <strong style={{color: '#006400'}}>{comm[0].TotHT.toFixed(3)}</strong> 
                    </td>
                    <td />
                    </tr>
                    <tr style={{backgroundColor:"#e7e7e7"}}>
                    <td />  
                    <td align='right'>
                      <strong>Tot.Tva</strong>
                    </td>
                    <td style={{color: '#0000CD'}}>
                    {comm[0].TotTva.toFixed(3)}
                    </td>
                    <td>
                      <strong >Tot.Fodec</strong>
                    </td>
                    <td style={{color: '#0000CD'}}>0.000</td>
                    <td>
                      <strong >Net.TTC </strong>
                    </td>
                    <td >
                    <strong style={{color: 'red'}}>{comm[0].TotTTC.toFixed(3)} </strong> 
                    </td>
                    <td />
                  </tr> 
                  </>
}
                </MDBTableBody>
                
              </MDBTable>
      
    </div>
  );
}

export default  DetailsCmd;
