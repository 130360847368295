import React from 'react'

import {
    MDBEdgeHeader,  MDBContainer,
    MDBCol,
    MDBRow,
    MDBCardBody,
    MDBIcon,
    MDBBtn,MDBCard , MDBBadge ,  MDBAnimation,MDBInput, MDBAlert, MDBModalBody,MDBModal,MDBModalHeader ,MDBPagination, MDBPageItem, MDBPageNav,MDBCarousel, MDBCarouselInner, MDBCarouselItem,

   
  } from 'mdbreact';
  import './HomePage.css'; 
 
  import { useLocation } from "react-router-dom";
   

  import { connect } from "react-redux";
  import { fetchProducts } from "../actions/productActions";
  import { addToCart  } from "../actions/cartActions";
  import { fetchUser } from "../actions/loginAction";


import Marq from './marq';
import spinner from './spinner.gif';
import Nav from './Nav';


import Footer from '../Footer';
function Home(props) {


  React.useEffect(() => {
console.log(localStorage.getItem('jwtname'))
if (!localStorage.getItem('jwtname')) {
  props.history.push('/login');
}
   
   if (props.products){ setLoad(false)  ;setAlert(true) 
   }

   if (props.error){ setLoad(false) 
   }
  
     },[])
   
  
    const [items, setItems] = React.useState([{
      id:0,
      art:0,
      count: 0 
    }]);
    const [id, setid] = React.useState(1);
    const [query, setQuery] = React.useState("");
    const [search, setsearch] = React.useState("");
    const [Count, setcount] = React.useState(0);
    const [COT , setCOT ] = React.useState(false);
    const [Load, setLoad] = React.useState(false);
    const [Not, setNot] = React.useState('invisible');
    const [produit, setProduct] = React.useState(false);

    const [pageCount, setpageCount] = React.useState(3);
    const [selectedPage, setselectedPage] = React.useState(1);
    const [offset, setoffset] = React.useState(0);

    const [modal, setmodal] = React.useState(false);
    const [Alert, setAlert] = React.useState(false);
    const name=localStorage.getItem("jwtname")

    const Page  = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
  const handleClick = (product ) => { 
 
    if( Count<=0 ||Count ==='' ){   setCOT(true) }
    else{ props.addToCart(product,Count ) ;
       setcount(0)
       setCOT(false)
     } }


     const handleInputChange = (event) => {
      setQuery(event.target.value);
    };
     

     const handleZoom = (pro)=> {
       setProduct(pro);
       setmodal(true)
     }

     
    
    const handelPageClick = (e)=> {
    
    const selectedPage = e.selected;
    setselectedPage(selectedPage);
    setoffset(offset);
    window.scrollTo(0, 0);
     
    }
   
  
    const {...rest} =props;

    const PageItem = ()=> {

    for (let i = 0; i < props.products.length/10; i++) {
     <MDBPageItem active onClick={(e)=> setoffset(i)}>
      <MDBPageNav className="page-link">
       {i}
      </MDBPageNav>
    </MDBPageItem  >

     
     
    } 
    
  }
    return (
      
         // style={{marginTop:'120px'}}
          
        <div   >
       
          
        
        <Nav  query={query} handleInputChange={handleInputChange}/>
       
            <Marq />
             <Footer/>
         
        
         

       
        </div>

      
       
      );
}

export default connect(
    (state) => ({ 
      error: state.products.error ,
      cartItems: state.cart.cartItems,
      user:state.pass.user,
      isAuthenticated: state.pass.isAuthenticated ,
      loading:state.products.loading
    
    }),
    {
      fetchProducts,
      addToCart,
      fetchUser,
    }
  )(Home)



/*   <MDBCarouselInner>
                                        <MDBCarouselItem itemId="1" onClick={() => handleZoom(product)}>

                                          <Img product={product.imgArt} style={{ width: '100%', height: '150px', border: '0.5px solid ', borderRadius: '10px', postion: '' }} />
                                        </MDBCarouselItem>
                                        <MDBCarouselItem itemId="2" onClick={() => handleZoom(product)}>
                                          <Img product={product.imgArt} style={{ width: '100%', height: '150px', border: '0.5px solid ', borderRadius: '10px', postion: '' }} />

                                        </MDBCarouselItem>
                                        <MDBCarouselItem itemId="3" onClick={() => handleZoom(product)}>
                                          <Img product={product.imgArt} style={{ width: '100%', height: '150px', border: '0.5px solid ', borderRadius: '10px', postion: '' }} />

                                        </MDBCarouselItem>

                                      </MDBCarouselInner>
                                    </MDBCarousel>
*/
