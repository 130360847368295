export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FILTER_PRODUCTS_BY_SIZE = "FILTER_PRODUCTS_BY_SIZE";
export const ORDER_PRODUCTS_BY_PRICE = "ORDER_PRODUCTS_BY_PRICE";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CREATE_ORDER = "CREATE_ORDER";
export const CLEAR_ORDER = "CLEAR_ORDER";
export const CLEAR_CART = "CLEAR_CART";
export const FETCH_ORDERS = "FETCH_ORDERS";  
 
export const FETCH_USER = "FETCH_USER";
export const FETCH_IMG = "FETCH_IMG";
export const FETCH_IMGART = "FETCH_IMGART";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const ERROR_LOGIN = "ERROR_LOGIN"; 

export const ERROR_PRODUCTS = "ERROR_PRODUCTS";
export const CLEAR_PRODUCTS ="CLEAR_PRODUCTS"

export const  PRODUIT_LOADING = " PRODUIT_LOADING";
export const FETCH_COM= "FETCH_COM"; 
