import {
  ERROR_LOGIN,
     FETCH_USER,SET_CURRENT_USER 
    
  } from "../types";
  import isEmpty from '../validation/is-empty';

  export const userReducer = (state = {isAuthenticated: false,
  user: {}}, action) => {
    switch (action.type) {
      case FETCH_USER:
        return  action.payload ;
        case SET_CURRENT_USER:
          return {
           
            isAuthenticated: !isEmpty(action.payload),
            user: action.payload.token
          };

          case ERROR_LOGIN:
            return  {error: action.payload }
      default:
        return state;
    }
  };
  