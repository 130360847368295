import React from 'react';
import HomePage from './comp/HomePage';
import Home from './comp/Home';

import Login from './comp/login';
import Marq from './comp/marq';
import { BrowserRouter, Route} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import Cart from "./comp/Cart"
import Orders from "./comp/test1"

import Produit from "./comp/produit"
import ProdFilter from "./comp/ProdFilter"






  
  
 
  



class Rout extends React.Component {
    render() {
      return (
        <Provider store={store}>
        <BrowserRouter>
           
            
           <Route exact path='/'component={Home} />
           <Route exact  path='/login' component={Login} />
           <Route exact  path='/Cart' component={Cart} />
           <Route exact  path='/Orders' component={Orders} />
           <Route exact  path='/Produits' component={Produit} />
           <Route exact  path='/ProdFilter' component={ProdFilter} />

     
        </BrowserRouter>
      </Provider>
      );
    }
  }
  
  export default Rout;