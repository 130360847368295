import {
    FETCH_IMG,
    
  } from "../types";
  
  export const imgReducer = (state = {}, action) => {
    
    switch (action.type) {
      case FETCH_IMG:
        return  { images: action.payload} ;
      default:
        return state;
    }
  };
  