import React from 'react'
import {
  MDBEdgeHeader, MDBContainer,
  MDBCol,
  MDBRow,
  MDBCardBody,

  MDBCard, MDBBadge, MDBAnimation, MDBInput, MDBAlert, MDBModalBody, MDBModal, MDBModalHeader, MDBPagination, MDBPageItem, MDBPageNav, MDBCarousel, MDBCarouselInner, MDBCarouselItem,


} from 'mdbreact';
import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import './HomePage.css';
import Footer from '../Footer';

import { connect } from "react-redux";
import { fetchProducts } from "../actions/productActions";
import { removeFromCart, addToCart } from "../actions/cartActions";
import { fetchUser, setCurrentUser } from "../actions/loginAction";


import spinner from './spinner.gif';

import Nav from './Nav';
import { useLocation } from "react-router-dom";

function Produit(props) {
  const location = useLocation();
  const { cartItems } = props;
  console.log(location.state)

  React.useEffect(() => {
console.log(location.state)
    props.fetchProducts(location.state.detail, 0)

    if (!localStorage.getItem('jwtname')) {
      props.history.push('/login');
    }
    if (props.products) {
      setLoad(false); setAlert(true)
    }

    if (props.error) {
      setLoad(false)
    }

  }, [location])


  const [query, setQuery] = React.useState("");
  const [id, setid] = React.useState(1);

  const [search, setsearch] = React.useState("");
  const [Count, setcount] = React.useState(0);
  const [COT, setCOT] = React.useState(false);
  const [Load, setLoad] = React.useState(false);
  const [Not, setNot] = React.useState('invisible');
  const [produit, setProduct] = React.useState(false);

  const [pageCount, setpageCount] = React.useState(3);
  const [selectedPage, setselectedPage] = React.useState(1);
  const [offset, setoffset] = React.useState(0);

  const [modal, setmodal] = React.useState(false);
  const [Alert, setAlert] = React.useState(false);
  const name = localStorage.getItem("jwtname")


  const handleClick = (product, Count) => {

    props.addToCart(product, Count);

    setCOT(false)
  }





  const handleZoom = (pro) => {
   
    setProduct(pro);
    setmodal(true)
  }

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };





  return (
    <>
   <Nav marque={location.state.marque} model={location.state.modele} query={query} handleInputChange={handleInputChange}/>

      <div className='container'  >
       


        {/* {props.error && (<div className='mt-5 p-5'><MDBRow center>

            < MDBAlert color="danger" size='md'>
               <h5> <MDBIcon icon="info-circle"  className='danger-text' /> aucun article n'a été trouvé </h5> 
            </MDBAlert>


          </MDBRow></div> ) } */}

         <MDBRow center>



          <MDBModal centered isOpen={modal} size='md'  >
            <MDBModalHeader toggle={() => setmodal(false)} >  {produit.Codart} </MDBModalHeader>
            <MDBRow center className='mx-2'>
              <img src={produit.imgArt}  />
            </MDBRow>

            <MDBModalBody>
            </MDBModalBody>

          </MDBModal>




        </MDBRow> 
        { <MDBRow className='mt-4 ' >
          <MDBCol sm={4} ></MDBCol>
          <MDBCol sm={4} >
            {props.loading && (

              <div >
                <img

                  src={spinner}
                  style={{ justifycontent: 'center', width: '200px', display: 'block', float: 'center' }}
                  alt=""
                />
                <h3 className='mx-5'>Loading...</h3>
              </div>)}

          </MDBCol>
          <MDBCol sm={4} ></MDBCol>
        </MDBRow> }

{props.products && 

<MDBContainer >

  {/* <MDBRow className='pt-3' end>



<button type="button" class="btn btn-link dropdown-toggle" data-mdb-toggle="dropdown" aria-expanded="false">
Tri Par
</button>
<ul class="dropdown-menu">
<li><a class="dropdown-item" href="#" on onClick={ascorder()}>A-z</a></li>
<li><a class="dropdown-item" href="#">z-a</a></li>
<li><a class="dropdown-item" href="#">Something else here</a></li>
<li><hr class="dropdown-divider" /></li>
<li><a class="dropdown-item" href="#">Separated link</a></li>
</ul>

</MDBRow> */}
  <MDBRow >
    <MDBCol >

    <MDBRow className='row-cols-md-2 row-cols-md-4 d-flex'  >

        

        {props.products.map((row, i) => (
          <MDBCol >

<MDBCard style={{ marginTop: 10, borderWidth: 2 ,width:"320px"}} key={row.Codart}

              border={props.cartItems.find(x => x.IDArt == row.IDArt) && "success"}    >


              {/* <MDBAnimation reveal type='fadeInLeft'> */}
              <MDBContainer   >
                <MDBRow className='mb-2' around>
                  <MDBCol className='mt-2'><p style={{ fontSize: '0.75em', textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} >  {row.Codart} </p></MDBCol>
                  <MDBCol className='mt-2'>    <img src={ row.Urlimg} style={{ width: '100px', height: '70px'}} />  </MDBCol>
                </MDBRow >
                <MDBRow center onClick={() => {handleZoom(row)} }  >
              
                 
                    <img src={row.imgArt}
                       style={{ width: '15em', height: '15em' }} />





                </MDBRow>
                <MDBRow center>

                <div className='text' style={{ textDecoration: 'underline', fontSize: "0.75em", marginTop: "10px"
                            // , textOverflow: "ellipsis",
                            //  overflow: "hidden", 
                            //  whiteSpace: "nowrap" 
                             }} center><strong >{row.LibArt}</strong></div>

                </MDBRow>
                <MDBRow

                  onClick={() => setProduct(row.imgArt)}>
                  <MDBCol className='mt-2' >      <p > {row.Qt > 0 ? (<MDBBadge color="success"> En Stock </MDBBadge>) : (<MDBBadge color="danger"> En Rupture  </MDBBadge>)} </p> </MDBCol>

                  <MDBCol><strong > <p className='text-center ' style={{ marginTop: 10 }}>{row.PrixVente.toFixed(3)} <sup>TND</sup> </p> </strong></MDBCol>
                </MDBRow>

              </MDBContainer>

              {cartItems.find(x => x.IDArt == row.IDArt) ?
                <MDBRow className='justify-content-center'>


                  {/* <MDBBadge  color='primary'> <MDBIcon icon='minus' size='2x' 

                      onClick={() => {
                        handleClick(product, -1);
                        // if(0>=Count) return;
                        // setcount(prevCount => prevCount -1);

                      }
                      } />  </MDBBadge> */}

                  <MDBBtn outline floating tag='a'
                  onClick={() => {

                    handleClick(row, -1);
                    // if(produit.Qt<=Count)return;
                  }}>
                    <MDBIcon className='black-text py-1 px-1' icon='minus' size='2x' color='primary' />
                  </MDBBtn>
                  {/* <MDBIcon className='black-text py-1 px-1 mx-2' icon='minus' size='2x' color='primary'
          style={{background : '#e7e7e7', padding: "15px", borderRadius: '30px' }}
          onMouseOver={(event) => { event.target.style.background = '#fdd835' }}
          onMouseOut={(event) => event.target.style.background = '#e7e7e7'}
          onClick={() => {

            handleClick(product, -1);
            // if(produit.Qt<=Count)return;
          }}>
        </MDBIcon> */}



                  <input
                    name={row.Codart}
                    type="text"
                    className="d-flex mt-2"
                    id={row.IDArt}
                    style={{ width: '50px', height: '98%' }}
                    value={cartItems.find(X => X.Codart === row.Codart)?.count || 0}
                    onMouseEnter={(e) => setid(e.target.name)}
                    onKeyDown={(e) => { e.key === 'Enter' & row.Qt > 0 ? (handleClick(row)) : (null) }}
                    onChange={(e) => { setcount(Number(e.target.value)); setid(row.Codart); console.log(Count) }}
                    onLoad={e => e.target.value}
                  />
                  <MDBBtn className='me-3' outline floating tag='a'
                  onClick={() => {

                    handleClick(row, 1);
                    // if(produit.Qt<=Count)return;
                  }}>
                    <MDBIcon className='black-text py-1 px-1' icon='plus' size='2x' color='primary' />
                  </MDBBtn>
                  <MDBBtn outline floating tag='a' color='white'
                    onClick={() => {
                      props.removeFromCart(row);
                    }}>
                    <MDBIcon className='red-text py-1 px-1' icon='trash-alt' size='2x' color='danger' />
                  </MDBBtn>
                  {/* <MDBIcon className='black-text py-1 px-1 mx-2' icon='plus' size='2x' color='primary'
          style={{background : '#e7e7e7', padding: "15px", borderRadius: '30px', color: 'red' }}
          onMouseOver={(event) => { event.target.style.background = '#fdd835' }}
          onMouseOut={(event) => event.target.style.background = '#e7e7e7'}
          onClick={() => {

            handleClick(product, 1);
            // if(produit.Qt<=Count)return;
          }}>
        </MDBIcon>
       */}

                  {/* <MDBBadge  color='primary'>
                      <MDBIcon icon='plus' size='2x' 
                        name={product.Codart}
                        onClick={() => {
                          setcount(prevCount => prevCount + 1);
                          handleClick(product, 1);
                          // if(produit.Qt<=Count)return;
                        }}>
                      </MDBIcon>
                    </MDBBadge>
                    */}




                </MDBRow> : (<MDBRow center ><MDBBtn className='white-text' style={{ width: '70%', borderRadius: '10px' }} size='sm' color='red'

                  onClick={() => { setcount(prevCount => prevCount + 1); handleClick(row, 1) }}  >
                  <MDBIcon fas icon="cart-plus" /> Ajouter au panier</MDBBtn> </MDBRow>)}


              <MDBRow >




                <MDBCol className='justify-content-center' size='6'  >
                  {/* <Img product={product.imgMarque} style={{ width: '80%', height: '50px' }} />
                            <hr className='my-2' />
                            <MDBRow center>
                              <p className='mt-2' >  {product.Codart} </p>
                                 
                              </MDBRow >
                              <hr className='my-2' /> */}
                  {row.Qt > 1 && <MDBRow around className='mt-2' >





                    {COT & id === row.Codart ? (<MDBRow center>


                      <label className='red-text ' style={{ marginTop: 10, fontSize: '15px' }} ><MDBIcon icon="info-circle" className='red-text ' />  Qte.invalide </label>



                    </MDBRow>) : (null)}





                  </MDBRow>}




                </MDBCol>
              </MDBRow>

              {/* </MDBAnimation> */}

              {/* <MDBCardFooter className='mt-2 text-center'>

                <MDBRow center> </MDBRow>*/}



            </MDBCard>
          </MDBCol>


        ))}
      </MDBRow >

      <MDBRow>

      </MDBRow>

      <MDBRow className='mt-2' size="10" center>
        {/* <SweetPagination
          currentPageData={setCurrentPageData}
          dataPerPage={12}
          getData={products}
          navigation={true}
          
        /> */}

        {/* <MDBPagination circle>

          {width > 728 && <MDBPageItem disabled={offset === 0}>
            <MDBPageNav className="page-link" onClick={(e) => { setoffset(0); window.scrollTo(0, 0) }} >
              <span>Première</span>
            </MDBPageNav>
          </MDBPageItem>}
          <MDBPageItem >
            <MDBPageNav className="page-link" aria-label="Previous" onClick={(e) => { setoffset(offset - 1); window.scrollTo(0, 0) }}>
              <span aria-hidden="true">&laquo;</span>
            </MDBPageNav>
          </MDBPageItem>
          {Page.slice(0 + x, long / 12 > 10 ? 10 + 1 + x : Math.round(long / 12)).map((i) =>
            <MDBPageItem disabled={offset === i} active onClick={(e) => { setoffset(i); window.scrollTo(0, 0); handelCount(i) }} >
              <MDBPageNav className="page-link">
                {i + 1}
              </MDBPageNav>
            </MDBPageItem  >
          )}
          {long / 12 > 10 && <>
            <MDBPageItem>
              <MDBPageNav  >
                ...
              </MDBPageNav>
            </MDBPageItem>
            <MDBPageItem disabled={offset === Math.round(long / 12)} active onClick={(e) => { setoffset(Math.round(long / 12 - 1)); window.scrollTo(0, 0); handelCount(Math.round(long / 12 + 1)) }} >
              <MDBPageNav className="page-link"  >
                {Math.round(long / 12)}
              </MDBPageNav>
            </MDBPageItem>
          </>
          }

          <MDBPageItem> */}
        {/* 
            <MDBPageNav className="page-link" onClick={(e) => { setoffset(offset + 1); window.scrollTo(0, 0) }} >
              &raquo;
            </MDBPageNav>
          </MDBPageItem>

          {width > 728 && <MDBPageItem>
            <MDBPageNav className="page-link" onClick={(e) => { setoffset(Math.round(long / 12 - 1)); window.scrollTo(0, 0) }} >
              dernière
            </MDBPageNav>
          </MDBPageItem>}
        </MDBPagination>*/}

      </MDBRow>

    </MDBCol  >






  </MDBRow>

</MDBContainer>

}


      </div>
      <hr/>
      <Footer/>
    </>
  );
}

export default connect(
  (state) => ({
    products: state.products.filteredItems,
    error: state.products.error,
    cartItems: state.cart.cartItems,
    user: state.pass.user,
    isAuthenticated: state.pass.isAuthenticated,
    loading: state.products.loading

  }),
  {
    removeFromCart,
    fetchProducts,
    addToCart,
    setCurrentUser,
    fetchUser,
  }
)(Produit)



/*   <MDBCarouselInner>
                                        <MDBCarouselItem itemId="1" onClick={() => handleZoom(product)}>

                                          <Img product={product.imgArt} style={{ width: '100%', height: '150px', border: '0.5px solid ', borderRadius: '10px', postion: '' }} />
                                        </MDBCarouselItem>
                                        <MDBCarouselItem itemId="2" onClick={() => handleZoom(product)}>
                                          <Img product={product.imgArt} style={{ width: '100%', height: '150px', border: '0.5px solid ', borderRadius: '10px', postion: '' }} />

                                        </MDBCarouselItem>
                                        <MDBCarouselItem itemId="3" onClick={() => handleZoom(product)}>
                                          <Img product={product.imgArt} style={{ width: '100%', height: '150px', border: '0.5px solid ', borderRadius: '10px', postion: '' }} />

                                        </MDBCarouselItem>

                                      </MDBCarouselInner>
                                    </MDBCarousel>
*/
