import React, { useEffect } from 'react';
import {  MDBBtn} from 'mdbreact';
import { Button, Col, Row, Dropdown, ButtonGroup, Modal, Form } from '@themesberg/react-bootstrap';
import { connect } from 'react-redux'
import {fetchOrders, fetchCom } from '../actions/orderActions'
import Nav from './Nav';
import Footer from '../Footer';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, dateFilter,numberFilter } from 'react-bootstrap-table2-filter';
import formatCurrency from "../util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faEye } from '@fortawesome/free-solid-svg-icons';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ExpandRow from './ExpandRow';
import {



  MDBModal, MDBModalBody,
  MDBIcon, MDBModalHeader
} from 'mdbreact';
import { MDBModalDialog, MDBModalContent } from 'mdb-react-ui-kit';
import DetailsCmd from './DetailsCmd';

const Orders = (props) => {
  const [modal, setmodal] = React.useState(false);
  const [Type,setType]=React.useState('cmd')
  const [numcom, setnumcom] = React.useState([])
  const [DATA, setData] = React.useState([])
  const [commandes, setcommandes] = React.useState([])
  const [ expanded, setexpanded] = React.useState([null])
  const {com, orders}= props

  const niveau=localStorage.getItem("niveau")


  

var testData = []
  if(DATA){DATA.map((ord,i) => (   
    testData.push({
      id:i     ,
  nf: ord.nf,
    Date: ord.datuser.substr(0, 16).replace('T','  '),
    PrixTotal: ord.mntdebit ,
    libtiers:ord.libtiers
    
  },)   )
  
  
  
  )  }
  
  
  
    const columns = [
   {
    
    dataField: 'nf',
    text: 'Numéro',
  }  , {
    
    dataField: 'libtiers',
    text: 'Client',
  }, {
    dataField: 'Date',
    text: 'Date',
    filter: dateFilter()

    
  }, {
    dataField: 'PrixTotal',
    text: 'PrixTotal',
    filter: numberFilter(),
    formatter: (cellContent, row)=>(cellContent >0 ? cellContent.toFixed(3) :null)


  },{
    dataField: '',
    text: 'action',

    formatter: (cellContent, row) => (
      <div className='d-flex justify-content-around'>
        <Button variant="outline-primary" size="sm" onClick={() => { setnumcom(row.nf);setmodal(true) }}
        style={{borderRadius:'30px'}} > 
        <FontAwesomeIcon icon={faEye} className="me-2" /> Détails</Button>
       
        {/* <Button variant="outline-danger" className='mx-1' size="sm"
            onClick={() => Deleteprojet(row.CodProjet)} > <FontAwesomeIcon icon={faTrash} className="me-2" color='red' /></Button>*/}
      </div>
    )
  }];
  const handleOnExpand = (row, isExpand) => {
    if (isExpand) {
      setexpanded(row.id);

      props.fetchCom(row.NumCommande);
      

    }
  }


  
  
  
 

 

  const expandRow = {

    renderer: row => (
      <div>
      <ExpandRow row={row} />
  
    </div>
    ), 
    onExpand: handleOnExpand ,
    onlyOneExpanding: true,

  };
  const defaultSorted = [{
    dataField: 'name',
    order: 'desc'
  }];
  const selectRow = {
    mode: 'radio',

    clickToSelect: true,

  };
  const handelfetch=()=>{
    props.fetchOrders () ;
        setData(orders)
  }

  return (
    <>
    <Nav/>
    
    <div className='container my-5'   >
        <h3>Liste des commandes client</h3>
      <MDBBtn  color='black' onClick={handelfetch}>Lancer</MDBBtn>
    
       <BootstrapTable
       keyField="NumCommande"
       data={testData}
       columns={ columns }
       
       defaultSorted={ defaultSorted  } 
       filter={ filterFactory() }

       condensed

      //  expandRow={  expandRow }
       pagination={ paginationFactory() }
     />

  
<MDBModal 
scrollable isOpen={modal}

 className="modal-secondary" 
 centered size='xl' >
       
          <MDBModalContent>
            <MDBModalHeader toggle={() => setmodal(false)} centered>
              Détails Commande n° :  {numcom}  </MDBModalHeader>

            <MDBModalBody  >
              <DetailsCmd row={numcom} />

            </MDBModalBody>
          </MDBModalContent>
        
      </MDBModal> 


                </div>
                <hr/>
                <Footer/>
                </>
  );
}

export default connect(
    (state) => ({ orders: state.order.orders,
      com:state.order.com
    }),
    {
        fetchOrders,fetchCom
     
    }

) (Orders)
